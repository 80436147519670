import React from 'react';
import {faCheck } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import "./Stepper.scss";


type Step = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
};

type StepperProps = {
  steps: Step[];
  activeStep: number;
  setStep: any;
};

const Stepper: React.FC<StepperProps> = ({ steps, activeStep,setStep }) => {
  return (
    <div className="stepper">
      {steps.map((step, index) => (
        <div className={`step ${index === activeStep ? 'step-active' : index < activeStep ? 'step-completed' : ''}`} key={index} onClick={()=>{if(index < activeStep){setStep(index)}}}>
          <div className="icon">{index < activeStep ? <FontAwesomeIcon className="icon-wizzard" icon={faCheck}/> :step.icon}</div>
          <div className="info">
            <div className="title step-text">{step.title}</div>
            <div className="subtitle step-text">{step.subtitle}</div>
          </div>
          {index !== steps.length - 1 && <div className="connector"></div>}
          {index !== steps.length - 1 && <div className="connector2"></div>}
        </div>
      ))}
    </div>
  );
};


export default Stepper;