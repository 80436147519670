////  Dev
// const config = {
//   urlBase: "http://ruvi01.cloud.itp360.com:9040",
// };


//////////// prod
const config = {
  urlBase: "https://ruvi01.cloud.itp360.com:9041",
};

export default config;
