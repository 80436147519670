import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import graficaFooter from "../../assets/images/Borde_naranja_footer.svg"
import logos from "../../assets/images/Logos_Fondo_Naranja-old.svg"
import "./Footer.scss"
import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

function Footer({color}:any) {
  const navigate = useNavigate();


  return (
 <div className={`rb-footer ${color === "secondary" ? "rb-footer-secondary" : ""}`}>
      <img
        className="rb-image-footer"
        src={graficaFooter}
        alt="mi imagen"
      />
      <div className="rb-footer-data">
        <div className="rb-footer-data-left">
          <div>Contáctanos</div>
          <div>Alcaldia Distrital de Santa Marta</div>
          <div>Palacio Municipal Calle 14 #2-49 Centro Histórico</div>
          <div>línea Nacional: 018000955532 </div>
          <div>línea fija : (+57) (5) 4209600 ext. 1212.</div>
          <div className="rb-footer-data-left-icon-container">
            <div className="rb-footer-data-left-icon">
              <FontAwesomeIcon icon={faFacebookF} />
            </div>
            <div className="rb-footer-data-left-icon rb-footer-data-left-icon-margin">
              <FontAwesomeIcon icon={faTwitter} />
            </div>
            <div className="rb-footer-data-left-icon rb-footer-data-left-icon-margin">
              <FontAwesomeIcon icon={faInstagram} /> 
            </div>
            <div className="rb-footer-data-left-info">@santamartadtch</div>
          </div>
        </div>
        <div className="rb-footer-data-right">
          <img
            className="rb-footer-data-right-logos"
            src={logos}
            alt="mi imagen"
          />
        </div>
      </div>
      <div className="rb-footer-copyright">
        Copyright ©2023 | Proyecto UDEP | Todos los derechos reservados.
      </div>
    </div>
  );
}

export default Footer;
