import axios from "axios";
import { config } from "../const/index";

export async function getDocumentConsulta(data: any, fileName: string) {
  try {
    const pathDownload =
      "/public/consult-user/pdf?" +
      "document_type=" +
      data.document_type +
      "&document_number=" +
      data.number;

    const response = await axios.get(`${config.urlBase}${pathDownload}`);

    console.log(response);
    const base64Data = response.data.result;
    const contentType = response.headers["content-type"];

    // Convertir el base64 a un objeto Blob
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });

    // Crear un objeto File a partir del Blob
    const file = new File([blob], fileName, { type: "pdf" });

    // Crear una URL de descarga y hacer clic en ella para iniciar la descarga
    const downloadUrl = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    // alert()
    link.click();
  } catch (error) {
    console.error("Error downloading document:", error);
  }
}
