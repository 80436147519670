import axios from "axios";
import { config } from "../const/index";

export function listService(data: any) {
  const auth:any = localStorage.getItem("auth")
  const token:any = JSON.parse(auth).access_token;
  var configGet = {
    headers: {
      Authorization: "Bearer " + token.replace(/['"]+/g, ""),
    },
  };
  return axios.get(`${config.urlBase}/admin/users?${data}`,configGet);
}
