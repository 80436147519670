import * as types from "../types"

export function postConsulta(data:any){
    return {
        type: types.POST_CONSULTA_REQUESTED,
        data
    }
}

export const resetStateConsults = () => {
    return {
      type: 'RESET_STATE-CONSULT'
    };
  };