import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Container } from "reactstrap";
import { Footer, Header } from "../../components";
import ModalRB from '../../components/Modal/Modal';
import { text } from "../../const";
import { postConsulta } from "../../redux/actions/consulta";
import { useNavigate } from 'react-router-dom';
import "./Consulta.scss"


function Registro() {
  const [form, setForm]: any = useState({ document_type: "CC", document_number: "" })
  const [modal, setModal] = useState(false);
  const [success, setSucces] = useState(false);
  const [textModal, setTextModal] = useState("")
  const consulta = useSelector((state: any) => state.consulta)
  const dispatch = useDispatch()
  const navigate = useNavigate();


  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handlesubmit = () => {
    dispatch(postConsulta(`?document_type=${form.document_type}&document_number=${form.document_number}`))
  };

  useEffect(() => {
    if (consulta.error && !consulta.consulta) {
      setModal(true);
      setSucces(false);
      setTextModal(`El número de documento ${form.document_number} NO SE ENCUENTRA REGISTRADO en el  Registro Único de Vendedores Informales – RUVI.`)
    } else {
      if (consulta.consulta) {
        navigate('/consulta-exitosa')
      }
    }
  }, [consulta])

  useEffect(()=>{ window.scrollTo(0, 0);},[])

  return (
    <>
      <ModalRB modal={modal} setModal={setModal} text={textModal} success={success} />
        <div className="d-flex flex-column min-vh-100">

          <Header color="secondary" />
          <Container className='flex-grow-1'>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-8 offset-lg-2'>
            <div className="rb-consulta">
                  <div className={`rb-card rb-card-consulta`}>
                    <div className="rb-consulta-title">
                      {text.consultaTitulo}
                    </div>
                    <div className="rb-consulta-form">
                      <div className="form-group rb-consulta-form-input">
                        <label htmlFor="tipoDocumento" className="rb-consulta-form-label">Tipos de Documentos:</label>
                        <select
                          id="tipoDocumento"
                          name="document_type"
                          className="form-select rb-input-form"
                          onChange={handleChange}
                        >
                          <option value="CC">CC</option>
                          <option value="CE">CE</option>
                          <option value="PAS">PAS</option>
                          <option value="PEP">PEP</option>
                        </select>
                      </div>
                      <div className="form-group rb-consulta-form-input">
                        <label htmlFor="numeroDocumento" className="rb-consulta-form-label">Número de Documento:</label>
                        <input
                          type="number"
                          className="form-control rb-input-form"
                          id="numeroDocumento"
                          name="document_number"
                          placeholder="Número de Documento"
                          onChange={handleChange}
                        />
                        <div className={`rb-button-container`}>
                          <button className="rb-button rb-button-consultar" onClick={handlesubmit}>Consultar</button>

                        </div>
                      </div>
                    </div>
                    <div className="rb-consulta-info">
                      {text.consultaInfo}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Footer color="secondary" />

        </div>
    </>
  );
}

export default Registro;
