import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import './FileInput.scss';

interface FileInputProps {
  onFileSelected: (file: File | null, base64: string | null) => void;
  acceptedTypes?: string;
}

const FileInput: React.FC<FileInputProps> = ({ onFileSelected, acceptedTypes }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = event.target.files?.[0] || null;

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        onFileSelected(file, base64);
      };
      reader.onerror = () => {
        onFileSelected(null, null);
      };
      reader.readAsDataURL(file);
    } else {
      onFileSelected(null, null);
    }

    setSelectedFileName(file ? file.name : null);
  };

  const handleButtonClick = (e:any) => {
    e.preventDefault();
    fileInputRef.current?.click();
  };

  const handleInputClick = (e:any) => {
    e.preventDefault();
    fileInputRef.current?.click();
  };

  return (
    <div className="custom-file-input-container">
      <div className="custom-file-input-wrapper">
        <input
          type="text"
          className="custom-file-input rb-inputs rb-inputs-select"
          readOnly
          placeholder="Adjuntar"
          value={selectedFileName || ''}
          onClick={handleInputClick}
        />
        <span className={`custom-file-icon-container ${selectedFileName ? 'custom-file-icon-container-hidden' : ''}`}>
          <FontAwesomeIcon icon={faPaperclip} className="custom-file-icon" />
        </span>
      </div>
      <button className="custom-file-button" onClick={handleButtonClick}>
        Subir
        <FontAwesomeIcon icon={faUpload} className="custom-file-icon-button" />
      </button>
      <input
        type="file"
        ref={fileInputRef}
        accept={acceptedTypes || undefined}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
    // <div className="mb-3">
    //   <input className="form-control" type="file" id="formFile"/>
    //   <label htmlFor="formFile" className="form-label">Default file input example</label>
    // </div>
  );
};

export default FileInput;
