import React, { useState, useEffect } from 'react';
import axios from 'axios';

const InstagramFeed: React.FC = () => {
  const [posts, setPosts] = useState<any[]>([]);

  useEffect(() => {
    fetchInstagramPosts();
  }, []);

  async function fetchInstagramPosts() {
    try {
      const response = await axios.get(
        `https://graph.instagram.com/me/media?fields=id,media_url,caption&access_token=YOUR_ACCESS_TOKEN`
      );

      const postsData = response.data.data;
      setPosts(postsData);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      {posts.map((post) => (
        <div key={post.id}>
          <img src={post.media_url} alt={post.caption} />
          <p>{post.caption}</p>
        </div>
      ))}
    </div>
  );
};

export default InstagramFeed;