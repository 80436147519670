import React, { useEffect } from "react";
import "./Twitter.scss"

const TwitterTimeline: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.charset = "utf-8";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="rb-individual-social-container">
      <a
        className="twitter-timeline"
        href="https://twitter.com/SantaMartaDTCH?ref_src=twsrc%5Etfw"
      >
        Tweets by SantaMartaDTCH
      </a>
    </div>
  );
};

export default TwitterTimeline;