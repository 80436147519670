import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import imageLogo from "../../assets/images/Logo_RUVI_azul.svg"
import logos from "../../assets/images/Logos_Fondo_Azul.svg"
import { Label, Input } from 'reactstrap';
import { text } from "../../const";
import "./Login.scss"
import { postLogin } from "../../redux/actions/login";

function Login() {
    const [form, setForm]: any = useState([])
    const dispatch = useDispatch()


    const handleChange = (e: any) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handlesubmit = () => {
        dispatch(postLogin(form))
    };

    useEffect(()=>{ window.scrollTo(0, 0);},[])
    
    return (
        <>
            <div className="rb-background">

            </div>
            <div className="rb-login">
                <div className="rb-login-top">
                    <img
                        className="rb-login-top-image"
                        src={imageLogo}
                        alt="mi imagen"
                    />
                    <Input
                        onChange={handleChange}
                        name="username"
                        type="text"
                        id="user"
                        placeholder={text.userPlaseholder}
                        className="rb-input rb-login-top-input" />
                    <Input
                        onChange={handleChange}
                        name="password"
                        type="password"
                        id="exampleEmail"
                        placeholder={text.passwordPlaseholder}
                        className="rb-input rb-login-top-input" />
                    <p className="rb-login-top-forgot">{text.forgotPassword}</p>
                    <button
                        className="rb-button rb-login-top-button"
                        onClick={handlesubmit}
                    >{text.login}</button>
                </div>
                <div className="rb-login-bottom">
                    <div className="rb-login-bottom-left">
                        <p className="rb-login-bottom-left-text">{text.loginFooterInfo1} <br />{text.loginFooterInfo2}</p>
                    </div>
                    <div className="rb-login-bottom-right">
                        <img
                            className="rb-login-bottom-right-logos"
                            src={logos}
                            alt="mi imagen"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
