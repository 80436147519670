import { Console } from 'console';
import React, { useEffect, useState } from 'react';
import { VerticalMenu, HeaderRegistro, Table } from '../../components';
import { editStatus } from '../../services/editStatus';
import { getDocuments } from '../../services/getDocument';
import { listService } from '../../services/listService';
import Modaledit from './Edit';
import ModalDelete from './Delete';
import "./Resultados.scss"
import { deleteRow } from '../../services/deleteRow';


const Resultados: React.FC = () => {
  const [desde, setDesde] = React.useState<any>("");
  const [hasta, setHasta] = React.useState<any>("");
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [info, setInfo]: any = React.useState([]);
  const [form, setForm] = React.useState([]);

  const [dataTable, setDataTable] = useState({
    titles: [{ title: "Certificado", key: "column1" },
    { title: "Fecha de Reg. ", key: "column2" },
    { title: "Nombre", key: "column3" },
    { title: "ID", key: "column4" },
    { title: "Nacionalidad  ", key: "column5" },
    { title: "Actividad", key: "column6" },
    { title: "Tipo de Vend", key: "column7" },
    { title: "Estado", key: "column8" },], info: [], actions: { title: "Opciones", delete: () => { }, edit: (e: any) => { handleEdit(e); console.log(e) }, deleteElement: (e: any) => { handleDelete(e) } }
  })
  interface Action {
    title: string;
    delete: () => void;
    edit: () => void;
  }
  interface Data {
    titles: { title: string; key: string }[];
    info: { [key: string]: string; badgeColor?: any }[][];
    actions?: Action;
  }

  useEffect(() => {
    getTabla()
  }, [])

  useEffect(()=>{ window.scrollTo(0, 0);},[])

  const getTabla = async () => {
    let info: any = []
    const dayDesde = desde instanceof Date ? String(desde.getDate()).padStart(2, '0') : "";
    const monthDesde = desde instanceof Date ? String(desde.getMonth() + 1).padStart(2, '0') : "";
    const yearDesde = desde instanceof Date ? desde.getFullYear() : "";
    const dayHasta = hasta instanceof Date ? String(hasta.getDate()).padStart(2, '0') : "";
    const monthHasta = hasta instanceof Date ? String(hasta.getMonth() + 1).padStart(2, '0') : "";
    const yearHasta = hasta instanceof Date ? hasta.getFullYear() : "";
    const tempDesde = desde instanceof Date ? `${yearDesde}-${monthDesde}-${dayDesde}` : ""
    const tempHasta = hasta instanceof Date ? `${yearHasta}-${monthHasta}-${dayHasta}` : ""

    console.log(dayDesde);
    console.log(monthDesde);
    console.log(yearDesde);
    let desdeQuery = tempDesde?.length > 0 ? "&added_after=" + tempDesde : "";
    let hastaQuery = tempHasta?.length > 0 ? "&added_before=" + tempHasta : "";
    await listService("limit=9999" + desdeQuery + hastaQuery)
      .then(({ data: result }) => {
        console.log(result.result);
        result.result.forEach((element: any, index: number) => {
          info[index] = []
          info[index].push({ column1: "Certificado", badgeColor: "orange" })
          info[index].push({ column2: element.created_at.substring(0, 10) })
          info[index].push({ column3: element.first_name + " " + element.last_name })
          info[index].push({ column4: element.document_number })
          info[index].push({ column5: element.birth_country })
          info[index].push({ column6: element.profession })
          info[index].push({ column7: element.seller_type })
          info[index].push({ column8: element.status, badgeColor: element.status === "APROBADO" ? "green" : element.status === "PENDIENTE" ? "yellow" : "red" })
          info[index].push({ pk: element.pk })
          info[index].push({ checked: false })
        });
      })
      .catch((error) => console.log(error));
    setDataTable({ ...dataTable, info })
  }


  const handleEdit = (row: any) => {
    // Lógica para editar el elemento
    setEditModal(true)
    setInfo(row)
    setForm(row[7]?.column8)
  };


  const handleDelete = (row: any) => {
    // Lógica para editar el elemento
    setDeleteModal(true)
    setInfo(row)
    setForm(row[7]?.column8)
  };

  const editPetition = (row: any) => {
    // Lógica para editar el elemento
    editStatus(info[8]?.pk, { status: form })
      .then(({ data: result }) => {
        // console.log(result.result);
        getTabla()
        setForm([])
        setInfo([])
        setEditModal(false)
      })
      .catch((error) => console.log(error));
    // console.log(form);
    // console.log(info[8]?.pk);
  };

  const deletePetition = (row: any) => {
    // Lógica para editar el elemento
    deleteRow(info[8]?.pk)
      .then(({ data: result }) => {
        // console.log(result.result);
        getTabla()
        setForm([])
        setInfo([])
        setDeleteModal(false)
      })
      .catch((error) => console.log(error));
    // console.log(form);
    // console.log(info[8]?.pk);
  };



  const actions = {
    onEdit: handleEdit,
    onDelete: handleDelete,
  };

  const downLoadExcel = () => {
    const dayDesde = desde instanceof Date ? String(desde.getDate()).padStart(2, '0') : "";
    const monthDesde = desde instanceof Date ? String(desde.getMonth() + 1).padStart(2, '0') : "";
    const yearDesde = desde instanceof Date ? desde.getFullYear() : "";
    const dayHasta = hasta instanceof Date ? String(hasta.getDate()).padStart(2, '0') : "";
    const monthHasta = hasta instanceof Date ? String(hasta.getMonth() + 1).padStart(2, '0') : "";
    const yearHasta = hasta instanceof Date ? hasta.getFullYear() : "";
    const tempDesde = desde instanceof Date ? `${yearDesde}-${monthDesde}-${dayDesde}` : ""
    const tempHasta = hasta instanceof Date ? `${yearHasta}-${monthHasta}-${dayHasta}` : ""

    let desdeQuery = tempDesde?.length > 0 ? "&added_after=" + tempDesde : "";
    let hastaQuery = tempHasta?.length > 0 ? "&added_before=" + tempHasta : "";
    const selectedPks: any = []
    dataTable.info.map((element: any) => {
      if (element[9].checked === true) {
        selectedPks.push(element[8].pk)
      }
    })
    getDocuments("limit=9999" + desdeQuery + hastaQuery, "document.xlsx", "excel", selectedPks)
  }

  const downLoadcsv = () => {
    const dayDesde = desde instanceof Date ? String(desde.getDate()).padStart(2, '0') : "";
    const monthDesde = desde instanceof Date ? String(desde.getMonth() + 1).padStart(2, '0') : "";
    const yearDesde = desde instanceof Date ? desde.getFullYear() : "";
    const dayHasta = hasta instanceof Date ? String(hasta.getDate()).padStart(2, '0') : "";
    const monthHasta = hasta instanceof Date ? String(hasta.getMonth() + 1).padStart(2, '0') : "";
    const yearHasta = hasta instanceof Date ? hasta.getFullYear() : "";
    const tempDesde = desde instanceof Date ? `${yearDesde}-${monthDesde}-${dayDesde}` : ""
    const tempHasta = hasta instanceof Date ? `${yearHasta}-${monthHasta}-${dayHasta}` : ""

    let desdeQuery = tempDesde?.length > 0 ? "&added_after=" + tempDesde : "";
    let hastaQuery = tempHasta?.length > 0 ? "&added_before=" + tempHasta : "";
    // console.log(dataTable.info)
    const selectedPks: any = []
    dataTable.info.map((element: any) => {
      if (element[9].checked === true) {
        selectedPks.push(element[8].pk)
      }
    })
    console.log(selectedPks);
    getDocuments("limit=9999" + desdeQuery + hastaQuery, "document.csv", "csv", selectedPks)
  }

  return (
    // <Inputs inputs={inputs} />
    <>
      <Modaledit modal={editModal} setModal={setEditModal} editPetition={editPetition} form={form} setForm={setForm} />
      <ModalDelete modal={deleteModal} setModal={setDeleteModal} deletePetition={deletePetition} form={form} setForm={setForm} />
      <div className='rb-resultados-container'><VerticalMenu />
        <div className='rb-resultados-content'>
          <HeaderRegistro totalTable={dataTable} />
          <div className='rb-result-table'>
            {/* <Table data={dataTable} /> */}
            <Table data={dataTable} setDataTable={setDataTable} desde={desde} hasta={hasta} setDesde={setDesde} setHasta={setHasta} getTabla={getTabla} downLoadExcel={downLoadExcel} downLoadcsv={downLoadcsv} />

          </div>


        </div>

      </div>
    </>
  );
};

export default Resultados;