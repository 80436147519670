import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./InputDate.scss";

interface CustomDateInputProps {
    placeholderText: string;
    selectedDate:Date | null;
    setSelectedDate: (value: string) => void;
}

const CustomDateInput: React.FC<CustomDateInputProps> = ({ placeholderText,selectedDate,setSelectedDate }) => {
    // const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
    };

    return (
        <div style={{ position: "relative", display: "inline-block" }}>
            <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                placeholderText={placeholderText}
                dateFormat="dd/MM/yyyy" // Formato de fecha personalizado
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="rb-custom-datapicker"
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="rb-calendar-icon" />
        </div>

    );
};

export default CustomDateInput;
