export const GET_PRODUCTS_REQUESTED = 'GET_PRODUCTS_REQUESTED'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED'

export const GET_DESCRIPTION_REQUESTED = 'GET_DESCRIPTION_REQUESTED'
export const GET_DESCRIPTION_SUCCESS = 'GET_DESCRIPTION_SUCCESS'
export const GET_DESCRIPTION_FAILED = 'GET_DESCRIPTION_FAILED'

export const POST_REGISTRO_REQUESTED = 'POST_REGISTRO_REQUESTED'
export const POST_REGISTRO_SUCCESS = 'POST_REGISTRO_SUCCESS'
export const POST_REGISTRO_FAILED = 'POST_REGISTRO_FAILED'

export const POST_LOGIN_REQUESTED = 'POST_LOGIN_REQUESTED'
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS'
export const POST_LOGIN_FAILED = 'POST_LOGIN_FAILED'

export const GET_LIST_REQUESTED = 'GET_LIST_REQUESTED'
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS'
export const GET_LIST_FAILED = 'GET_LIST_FAILED'

export const POST_CONSULTA_REQUESTED = 'POST_CONSULTA_REQUESTED'
export const POST_CONSULTA_SUCCESS = 'POST_CONSULTA_SUCCESS'
export const POST_CONSULTA_FAILED = 'GET_LIST_FAILED'