const urls = {
  home: "",
  login: "login",
  prueba: "prueba",
  registro: "registro",
  consulta: "consulta",
  consultaExitosa: "consulta-exitosa",
  resultado: "resultado",
  test: "test",
};

export default urls;
