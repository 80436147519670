import axios from "axios";
import { config } from "../const/index";

export function deleteRow(key: any) {
  const auth:any = localStorage.getItem("auth")
  const token:any = JSON.parse(auth).access_token;
  var configPatch = {
    headers: {
      Authorization: "Bearer " + token.replace(/['"]+/g, ""),
    },
  };
  return axios.delete(`${config.urlBase}/admin/users/${key}`,configPatch);
}
