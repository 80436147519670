import React from "react";
import "./Paginador.scss"

interface PaginadorProps {
  currentPage: number;
  totalPages: number;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  handleFirstPage: () => void;
  handleLastPage: () => void;
}

const Paginador: React.FC<PaginadorProps> = ({
  currentPage,
  totalPages,
  handlePreviousPage,
  handleNextPage,
  handleFirstPage,
  handleLastPage,
}) => {
  return (
    <div className="pagination">
      <button
        className="pagination-btn"
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
      >
        Anterior
      </button>
      {currentPage !== 1 && (
        <button
          className="pagination-btn"
          onClick={handleFirstPage}
          disabled={currentPage === 1}
        >
          1
        </button>
      )}
      {currentPage > 2 && totalPages > 4 && (
        <button className="pagination-btn" disabled={true}>
          ...
        </button>
      )}
      <button
          className="pagination-btn pagination-btn-active"
          disabled={true}
        >
          {currentPage}
        </button>
      {/* <span className="current-page">{</span> */}
      {currentPage < totalPages - 1 && (
        <button className="pagination-btn" disabled={true}>
          ...
        </button>
      )}
      {currentPage !== totalPages && (
        <button
          className="pagination-btn"
          onClick={handleLastPage}
          disabled={currentPage === totalPages}
        >
          {totalPages}
        </button>
      )}
      <button
        className="pagination-btn"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        Después
      </button>
    </div>
  );
};

export default Paginador;
