import React, { useEffect } from "react";
import "./Facebook.scss";

declare global {
  interface Window {
    FB: any;
  }
}

const FacebookPosts: React.FC = () => {
  useEffect(() => {
    const loadFacebookSDK = () => {
      if (window.FB) {
        window.FB.XFBML.parse();
      } else {
        setTimeout(loadFacebookSDK, 100);
      }
    };

    if (!document.getElementById("facebook-jssdk")) {
      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v17.0&appId=541813610334049&autoLogAppEvents=1";
      script.crossOrigin = "anonymous";
      script.nonce = "9IqVnenU";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }

    loadFacebookSDK();
  }, []);

  return (
    <div className="fb-page-container">
      <div className="fb-page" data-href="https://www.facebook.com/SantaMartaDTCH" data-tabs="timeline" data-width="200" data-height="500" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/SantaMartaDTCH" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/SantaMartaDTCH">UNO</a></blockquote></div>
    </div>
  );
};

export default FacebookPosts;
