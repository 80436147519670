import { Routes, Route } from "react-router-dom";
import { route } from "../models/index";
import { Consulta, ConsultaExitosa, Home,Login, Resultados, Test } from "../pages/Index";
import urls from "../const/urls";
import { Modal, Prueba } from "../components";
import Registro from "../pages/Registro/Registro";

function Index() {
  const routes: route[] = [
    {
      url: urls.home,
      component: Home,
    },
    {
      url: urls.login,
      component: Login,
    },
    {
      url: urls.prueba,
      component: Modal,
    },
    {
      url: urls.registro,
      component: Registro,
    },
    {
      url: urls.consulta,
      component: Consulta,
    },
    {
      url: urls.consultaExitosa,
      component: ConsultaExitosa,
    },
    {
      url: urls.resultado,
      component: Resultados,
    },
    {
      url: urls.test,
      component: Test,
    },
  ];
  return (
    <Routes>
      {routes.map((data: route, i: number) => {
        return (
          <Route key={i} path={`/${data.url}`} element={<data.component />} />
        );
      })}
    </Routes>
  );
}

export default Index;
