import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Select from "react-select";
import FileInput from '../FileInput/FileInput';
import ModalRB from '../Modal/Modal';
import './Input.scss';

interface InputOption {
  value: any;
  label: string;
}

interface Input {
  type: string;
  label: string;
  name: any;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  question?: boolean;
  multiple?: boolean;
  text?: string | undefined;
  lastDate?: number;
  options?: InputOption[];
}

interface Props {
  inputs: Input[];
  form: any;
  setForm: any;
}

const FormComponent: React.FC<Props> = ({ inputs, form, setForm }) => {
  const [modal, setModal] = useState(false);
  const [modalText, setModalText]: any = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFileSelected = (file: File | null, base: string | null, name: string) => {
    setForm({ ...form, [name]: base });
  };

  const getEighteenYearsAgoDate = (lastDate: number) => {
    const today = new Date();
    const eighteenYearsAgo = new Date(today);
    eighteenYearsAgo.setFullYear(today.getFullYear() - lastDate);
    return eighteenYearsAgo.toISOString().split("T")[0];
  };

  const renderInputs = () => {
    return inputs.map((input, index) => {
      const { type, label, name, required, placeholder, options, disabled, question, text, lastDate, multiple } = input;

      switch (type) {
        case 'text':

        case 'number':
          return (
            <div key={index} className="rb-input-container" id={name}>
              {required ? (
                <label className="rb-label">
                  {label}
                  <span className="rb-label-required">*</span>
                </label>
              ) : (
                <label className="rb-label">{label}</label>
              )}
              <input
                type={type}
                className="rb-inputs"
                name={name}
                required={required}
                placeholder={placeholder}
                onChange={(e) => handleChange(e, index)}
              />
            </div>
          );
        case 'date':
          return (
            <div key={index} className="rb-input-container" id={name}>
              {required ? (
                <label className="rb-label">
                  {label}
                  <span className="rb-label-required">*</span>
                </label>
              ) : (
                <label className="rb-label">{label}</label>
              )}
              {lastDate ? <input
                type={type}
                className={`rb-inputs ${form[name] ? 'rb-date-changed' : 'rb-date'}`}
                name={name}
                required={required}
                // min={getEighteenYearsAgoDate(lastDate)} // Establece la fecha mínima permitida (hace 18 años desde hoy)
                max={getEighteenYearsAgoDate(lastDate)} // Establece la fecha máxima permitida (fecha actual)
                onChange={(e) => handleChange(e, index)}
                disabled={disabled ? disabled : false}
              /> :
                <input
                  type={type}
                  className={`rb-inputs ${form[name] ? 'rb-date-changed' : 'rb-date'}`}
                  name={name}
                  required={required}
                  onChange={(e) => handleChange(e, index)}
                  disabled={disabled ? disabled : false}
                />}

            </div>
          );
        case 'select':
          return (
            <div key={index} className="rb-input-container" id={name}>
              {required ? (
                <label className="rb-label">
                  {label}
                  <span className="rb-label-required">* {question ? <FontAwesomeIcon className='rb-lable-question' icon={faCircleQuestion} onClick={() => { setModal(true); setModalText(text) }} /> : null}</span>
                </label>
              ) : (
                <label className="rb-label">{label} {question ? <FontAwesomeIcon className='rb-lable-question' icon={faCircleQuestion} onClick={() => { setModal(true); setModalText(text) }} /> : null}</label>
              )}
              <select
                name={name}
                required={required}
                className="rb-inputs rb-inputs-select"
                onChange={(e) => handleChange(e, index)}
                value={form[name] || options?.[0]?.value} // Valor por defecto
              >
                {options &&
                  options.map((option, optionIndex) => (
                    <option key={optionIndex} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </select>
            </div>
          );
        case 'file':
          return (
            <div key={index} className="rb-input-container" id={name}>
              {required ? (
                <label className="rb-label">
                  {label}
                  <span className="rb-label-required">*</span>
                </label>
              ) : (
                <label className="rb-label">{label}</label>
              )}
              <FileInput onFileSelected={(file, base) => handleFileSelected(file, base, name)} acceptedTypes="image/jpeg, image/png" />
            </div>
          );
        case 'title':
          // console.log(name)
          return (<div key={index} className="rb-input-container-title" id={name}>{label}</div>);
        case 'multiselect':
          return(<div key={index} className="rb-input-container" id={name}>
            {required ? (
              <label className="rb-label">
                {label}
                <span className="rb-label-required">* {question ? <FontAwesomeIcon className='rb-lable-question' icon={faCircleQuestion} onClick={() => { setModal(true); setModalText(text) }} /> : null}</span>
              </label>
            ) : (
              <label className="rb-label">{label} {question ? <FontAwesomeIcon className='rb-lable-question' icon={faCircleQuestion} onClick={() => { setModal(true); setModalText(text) }} /> : null}</label>
            )}
            <Select
              name={name}
              isMulti // Establece la propiedad isMulti en true
              options={options || []}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                setForm({ ...form, [name]: selectedValues });
              }}
              value={(options || []).filter(option => form[name]?.includes(option.value))} // Valor por defecto, si form[name] es un arreglo de valores
              // className="rb-inputs-select"
              isDisabled={disabled}
            />
          </div>)
        default:
          return null;
      }
    });
  };

  return <> <ModalRB modal={modal} setModal={setModal} text={modalText} success={true} onlyText={true} /><form className="rb-form-container">{renderInputs()}</form></>;
};

export default FormComponent;
