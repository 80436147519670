import { call, put, takeEvery } from "redux-saga/effects";
import { searchService } from "../../services/searchService";
import * as type from "../types";

function getApi(search: string) {
  return searchService(search)
    .then((res) => res)
    .catch((error) => {
      console.log("products error: ", error);
      {
        throw error;
      }
    });
}

function* fetchProducts(action: any): any {
  try {
    const products = yield call(getApi, action.search);
    yield put({ type: type.GET_PRODUCTS_SUCCESS, products: products });
  } catch (e: any) {
    yield put({ type: type.GET_PRODUCTS_FAILED, message: e.message });
  }
}

function* productsSaga() {
  yield takeEvery(type.GET_PRODUCTS_REQUESTED, fetchProducts);
}

export default productsSaga;
