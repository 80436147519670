import { combineReducers } from 'redux';
import description from './description';
import products from './products';
import registro from './registro';
import login from './login';
import list from './list';
import consulta from './consulta';

const rootReducer = combineReducers({
    products:products,
    description:description,
    registro:registro,
    login:login,
    list:list,
    consulta:consulta
})

export default rootReducer