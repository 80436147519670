import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoAlcaldia from "../../assets/images/LogoAlcaldia.svg";
import "./VerticalMenu.scss"


function VerticalMenu() {
  const navigate = useNavigate();

  const GetStorage = async() =>{
    const storage = localStorage.getItem('auth');
    if (!storage) {
      navigate('/login')
    }
  }

  useEffect(() => {
    GetStorage()
  }, [

  ])

  const logOut = () => {
    localStorage.removeItem('auth');
    navigate('/login')
  }

  return (
    <>
      <div className="rb-vertical-menu">
        <FontAwesomeIcon icon={faArrowRightFromBracket} onClick={logOut} />
        <div className="rb-vertical-logo-container">
          <img
            className="rb-vertical-logo"
            src={LogoAlcaldia}
            alt="mi imagen"
          /></div>
      </div>
    </>
  );
}

export default VerticalMenu;
