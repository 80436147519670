import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faMagnifyingGlass, faPlus, faFileExcel, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare, } from '@fortawesome/free-regular-svg-icons';
import "./TableActions.scss"
import InputDate from '../InputDate/InputDate';

interface Props {
    desde: any;
    hasta: any;
    data:any;
    setItemsPerPage: (value: number) => void;
    setDesde: (value: string) => void;
    setHasta: (value: string) => void;
    getTabla: () => void,
    downLoadExcel: () => void,
    downLoadcsv: () => void,
    selectAllRows: () => void,
    deselectAllRows: () => void,
}

const TableActions: React.FC<Props> = ({ setItemsPerPage, setDesde, setHasta, getTabla, downLoadcsv, downLoadExcel, desde, hasta, selectAllRows, deselectAllRows,data }) => {
    const [changeCheck, setChangeCheck] = useState(false);
    const changeItemsPerPage = (e: any) => {
        setItemsPerPage(parseInt(e.target.value))
    }
    return (
        <div className="rb-actions-container">
            <div className='rb-icons-container'>
                {changeCheck === false ? <FontAwesomeIcon className='rb-icons-container-item rb-actions-icon' icon={faSquare} onClick={() => {deselectAllRows();setChangeCheck(true)}} /> :
                    <FontAwesomeIcon className='rb-icons-container-item rb-actions-icon' icon={faCheckSquare} onClick={() => {selectAllRows();setChangeCheck(false)}} />
                }

                <FontAwesomeIcon className='rb-icons-container-item rb-actions-icon' icon={faSync} onClick={() => getTabla()} />
            </div>
            <div className='rb-size-container'>
                <span>Mostrar</span>
                <select className='rb-size-select' onChange={changeItemsPerPage}>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                </select>
                <span>registros</span>
            </div>
            <div className='rb-filter-container'>
                <div className='rb-filter-container-item'>
                    <InputDate placeholderText={"Desde"} setSelectedDate={setDesde} selectedDate={desde} />
                </div>
                <div className='rb-filter-container-item'>
                    <InputDate placeholderText={"Desde"} setSelectedDate={setHasta} selectedDate={hasta} />
                </div>
                <button className='rb-filter-container-item rb-filter-container-item-button rb-filter-container-item-search' onClick={() => { getTabla() }}>
                    <FontAwesomeIcon className='rb-filter-container-item-button-icon rb-filter-container-item-button-icon-search' icon={faMagnifyingGlass} />
                    <span>Buscar</span>
                </button>
                <button className='rb-filter-container-item rb-filter-container-item-button rb-filter-container-item-plus'>
                    <FontAwesomeIcon className='rb-filter-container-item-button-icon rb-filter-container-item-button-icon-plus' icon={faPlus} />
                    <span>Nuevo</span>
                </button>
                <FontAwesomeIcon onClick={() => { downLoadExcel() }} className='rb-filter-container-item rb-actions-icon rb-actions-icon-excel' icon={faFileExcel} />
                <FontAwesomeIcon onClick={() => { downLoadcsv() }} className='rb-filter-container-item rb-actions-icon rb-actions-icon-csv' icon={faFileCsv} />
            </div>

        </div>
    );
}

export default TableActions;