import * as type from "../types";
const initialState = {
  registro: null,
  loading: false,
  error: null,
};

export default function registro(state = initialState, action: any) {
  switch (action.type) {
    case type.POST_REGISTRO_REQUESTED:
      return {
        ...state,
        loading: true,
        registro: null,
      };
    case type.POST_REGISTRO_SUCCESS:
      return {
        ...state,
        loading: false,
        registro: action.registro.data.result,
      };
    case type.POST_REGISTRO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
        registro: null,
      };

    case "RESET_STATE_REGISTRO":
      return initialState;
    default:
      return state;
      break;
  }
}
