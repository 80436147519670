import { call, put, takeEvery } from "redux-saga/effects";
import { conultaService } from "../../services/consultaService";
import * as type from "../types";

function getApi(data: any) {
  return conultaService(data)
    .then((res) => res)
    .catch((error) => {
      console.log("consulta error: ", error);
      {
        throw error;
      }
    });
}

function* fetchConsulta(action: any): any {
  try {
    const consulta = yield call(getApi, action.data);
    yield put({ type: type.POST_CONSULTA_SUCCESS, consulta: consulta });
  } catch (e: any) {
    yield put({ type: type.POST_CONSULTA_FAILED, message: e.message });
  }
}

function* consultaSaga() {
  yield takeEvery(type.POST_CONSULTA_REQUESTED, fetchConsulta);
}

export default consultaSaga;
