import { Facebook, Footer, Header, Prueba, Twitter } from "../../components/index";
import { useNavigate } from 'react-router-dom';
import imageheader from "../../assets/images/camellon_de_la_bahia_de_santa_marta_1.jpg"
import camapanas_pedagogicas from "../../assets/images/camapanas_pedagogicas.jpeg"
import entrega_de_modulos_y_certificados from "../../assets/images/entrega_de_modulos_y_certificados.jpeg"
import espacio_publico_recuperado_k5 from "../../assets/images/espacio_publico_recuperado_k5.jpeg"
import { Container } from 'reactstrap';
import banner from "../../assets/images/Banner_Inicio.svg"
import patrimonios from "../../assets/images/Patrimonios.svg"
import { text } from "../../const";
import "./Home.scss"
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { useEffect, useState } from "react";

function Home() {

  const [hoveredCard, setHoveredCard]: any = useState('null');
  const navigate = useNavigate();

  function handleClick() {
    window.open('https://www.santamarta.gov.co/tags/udep', '_blank');
  }

  function handleClickRedirect(link: string) {
    window.open(link, '_blank');
  }

  useEffect(()=>{ window.scrollTo(0, 0);},[])
  
  return (
    <div className="rb-home">
      <Header></Header>


      <div className="header-image-background rb-image-container">

        <svg viewBox="0 0 500 60" preserveAspectRatio="none" style={{ height: "100%", width: "100%", position: "absolute", bottom: "-10%", left: 0, pointerEvents: "none" }}>
          <path d="M0,50 C150,55 350,55 500,50 L500,60 L0,60 Z" style={{ stroke: "none", fill: "#fff" }}></path>
        </svg>
        <Container> {/* Use responsive container */}
          <div className="row header-margin-top">

            <div className="col-sm-12 col-md-12 col-lg-6 header-left-column">

              <img
                src={banner}
                alt="mi imagen"
              />

            </div>


            <div className="col-sm-12 col-md-12 col-lg-6">
              <p className="rb-text-banner">{text.textBanner} <br /> <br />{text.textBanner2}</p>
              <div className="rb-buttons-banner">
                <button className="rb-button rb-button-consult" onClick={() => navigate('/consulta')}>{text.consultar}</button>
                <button className="rb-button rb-button-register" onClick={() => navigate('/registro')}>{text.registrarse}</button>
              </div>
            </div>

          </div>
        </Container>

      </div>
      <div className="rb-card-container">
        <Container> {/* Use responsive container */}

          <div className="row">

            <div className="col-md-12 col-lg-4 col-sm-12 rb-card-col-left">

              <Card className={`rb-card rb-card-home ${hoveredCard === "card1" ? "rb-hovered-card" : ""
                }`}
                onMouseEnter={() => setHoveredCard("card1")}
                onMouseLeave={() => setHoveredCard(null)}>
                <CardBody className="rb-body-card">
                  <img
                    className="rb-card-image"
                    src={camapanas_pedagogicas}
                    alt="mi imagen"
                  />
                  <br />
                  <p className="rb-text-card">{text.card1}</p>
                  <button className="rb-button rb-button-see-more" onClick={() => handleClickRedirect("https://www.santamarta.gov.co/sala-prensa/noticias/udep-recupero-5863-m2-de-espacio-publico-para-el-disfrute-de-todos-los-samarios")}>{text.verMas}</button>
                </CardBody>

              </Card>
            </div>
            <div className="col-md-12 col-lg-4 col-sm-12 rb-card-col-middle">

              <Card className={`rb-card rb-card-home ${hoveredCard === "card2" ? "rb-hovered-card" : ""
                }`}
                onMouseEnter={() => setHoveredCard("card2")}
                onMouseLeave={() => setHoveredCard(null)}>
                <CardBody className="rb-body-card">
                  <img
                    className="rb-card-image"
                    src={entrega_de_modulos_y_certificados}
                    alt="mi imagen"
                  />
                  <br />
                  <p className="rb-text-card">{text.card2}</p>
                  <button className="rb-button rb-button-see-more" onClick={() => handleClickRedirect("https://www.santamarta.gov.co/sala-prensa/noticias/alcaldia-distrital-entrega-modulos-y-certificaciones-vendedores-y-artesanos-del")}>{text.verMas}</button>


                </CardBody>
              </Card>
            </div>
            <div className="col-md-12 col-lg-4 col-sm-12 rb-card-col-right">
              <Card className={`rb-card rb-card-home ${hoveredCard === "card3" ? "rb-hovered-card" : ""
                }`}
                onMouseEnter={() => setHoveredCard("card3")}
                onMouseLeave={() => setHoveredCard(null)}>
                <CardBody className="rb-body-card">
                  <img
                    className="rb-card-image"
                    src={espacio_publico_recuperado_k5}
                    alt="mi imagen"
                  />
                  <br />
                  <p className="rb-text-card">{text.card3}</p>
                  <button className="rb-button rb-button-see-more" onClick={() => handleClickRedirect("https://www.santamarta.gov.co/sala-prensa/noticias/avanza-recuperacion-del-espacio-publico-en-el-primer-tramo-del-proyecto-de")}>{text.verMas}</button>

                </CardBody>
              </Card>
            </div>
          </div>
        </Container>
      </div>
      <br />
      <br />
      <br />
      <br />
      <img
        className="rb-image-patrimonios"
        src={patrimonios}
        alt="mi imagen"
      />
      <div className="rb-under-patrimonios"></div>
      <button className="rb-button rb-button-info" onClick={handleClick}>
        {text.info}
      </button>

      <div className="rb-social-container">
        <div className="rb-twitter-container">
          <Twitter />
        </div>
        <div className="rb-twitter-container">
          <Facebook />
        </div>
        <div className="rb-twitter-container">
          <Facebook />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
