import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import imageLogo from "../../assets/images/LogoTable.svg"
import "./HeaderRegistro.scss"


function HeaderRegistro({totalTable}:any) {

  const navigate = useNavigate();

  const GetStorage = async() =>{
    const storage = localStorage.getItem('auth');
    if (!storage) {
      navigate('/login')
    }
  }

  useEffect(() => {
    console.log("-----------------");
    console.log(totalTable)
    GetStorage()
  }, [

  ])

  const logOut = () => {
    localStorage.removeItem('auth');
    navigate('/login')
  }


  return (
    <>
      <div className="rb-header-registro">
        <div className="rb-header-registro-left">
          <div className="rb-header-registro-left-top">{totalTable.info.length}</div>
          <div className="rb-header-registro-left-bottom">Registrados</div>
        </div>
        <div className="rb-header-registro-right">
          <div className="rb-header-registro-right-left">
            <img
              className="rb-header-registro-right-left-logo"
              src={imageLogo}
              alt="mi imagen"
            />
          </div>

          <div className="rb-header-registro-right-right">
            <div className="rb-header-registro-right-right-wellcome">Bienvenido</div>
            <div className="rb-header-registro-right-right-username">Nombre de Usuario</div>
            <div className="rb-header-registro-right-right-session">
              <div className="rb-header-registro-right-right-session-count">
                <div className="rb-pointer-element">Cuenta</div>
              </div><div className="rb-header-registro-right-right-session-logout">
                <div className="rb-pointer-element" onClick={logOut}>Cerrar Sesión</div>

              </div>
            </div>

          </div>

        </div>
      </div>
    </>
  );
}

export default HeaderRegistro;
