const step2 = [
  {
    type: "title",
    label: "Información de La Actividad Económica",
    name: "inf_economic_activity",
  },
  {
    type: "select",
    label: "Tipo de vendedor",
    name: "seller_type",
    required: true,
    question: true,
    text: 'LEY 1988 DE AGOSTO DE 2019 - "POR LA CUAL SE ESTABLECEN LOS LINEAMIENTOS, PARA LA FORMULACION,IMPLEMENTACION y EVALUACIÓN DE UNA POLÍTICA PÚBLICA DE LOS VENDEDORES INFORMALES Y SE DICTAN OTRAS DISPOSICIONES"\n\n a) Vendedores Informales Ambulantes: Los que realizan su labor, presentan diversas expresiones artísticas o prestan sus servicios recorriendo las vías y demás espacios de uso público, sin estacionarse temporal o permanentemente en un lugar específico, utilizando sus capacidades, un elemento móvil portátil o su propio cuerpo para transportar las mercancías;\n\n b) Vendedores Informales Semi-estacionarios: Los que realizan su labor recorriendo las vías y demás espacios de uso público, estacionándose de manera transitoria en un lugar, con la facilidad de poder desplazarse a otro sitio distinto en un mismo día, utilizando elementos, tales como carretas, carretillas, tapetes, .. telas, maletas, cajones rodantes o plásticos para transportar las mercancías;\n\n c) Vendedores Informales Estacionarios: Son las personas que para ofrecer sus bienes o servicios se establecen de manera permanente en un lugar determinado del espacio público, previamente definido para la respectiva autoridad municipal o distrital, mediante la utilización de kioscos, toldos, vitrinas, casetas o elementos similares;\n\n d) vendedores informales periódicos: Realizan sus actividades en días específicos de la semana o del mes, o en determinadas horas del día en jornadas que pueden legar a ser inferiores a las ocho horas;\n\n e) Vendedores informales ocasionales o de temporada: Realizan sus actividades en temporadas o períodos específicos del año, ligados a festividades, o eventos conmemorativos, especiales o temporadas escolares o de fin de año;\n\n',
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      {
        value: "VENDEDOR INFORMAL SEMIESTACIONARIO",
        label: "VENDEDOR INFORMAL SEMIESTACIONARIO",
      },
      {
        value: "VENDEDOR INFORMAL AMBULANTE",
        label: "VENDEDOR INFORMAL AMBULANTE",
      },
      {
        value: "VENDEDOR INFORMAL ESTACIONARIO",
        label: "VENDEDOR INFORMAL ESTACIONARIO",
      },
      {
        value: "VENDEDOR INFORMAL PERIODICO",
        label: "VENDEDOR INFORMAL PERIODICO",
      },
      {
        value: "VENDEDOR INFORMAL OCASIONAL O DE TEMPORADA",
        label: "VENDEDOR INFORMAL OCASIONAL O DE TEMPORADA",
      },
    ],
  },
  {
    type: "text",
    label: "Dirección en el espacio público",
    name: "public_space_address",
    placeholder: "Dirección en el espacio público",
    required: true,
  },
  {
    type: "select",
    label: "Localidad",
    name: "locality",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      {
        value: "LOCALIDAD 1 - CULTURAL TAYRONA SAN PEDRO ALEJANDRINO",
        label: "LOCALIDAD 1 - CULTURAL TAYRONA SAN PEDRO ALEJANDRINO",
      },
      {
        value: "LOCALIDAD 2 - HISTÓRICA RODRIGO DE BASTIDAS",
        label: "LOCALIDAD 2 - HISTÓRICA RODRIGO DE BASTIDAS",
      },
      {
        value: "LOCALIDAD 3 - PERLA DEL CARIBE",
        label: "LOCALIDAD 3 - PERLA DEL CARIBE",
      },
    ],
  },
  {
    type: "text",
    label: "Barrio o sector",
    name: "neighborhood",
    placeholder: "Barrio o sector",
    required: true,
  },
  {
    type: "select",
    label: "Motivo de la elección del lugar",
    name: "locality_reason",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      {
        value: "CERCANÍA AL LUGAR DE RESIDENCIA",
        label: "CERCANÍA AL LUGAR DE RESIDENCIA",
      },
      {
        value: "ALTA AFLUENCIA DE PERSONAS",
        label: "ALTA AFLUENCIA DE PERSONAS",
      },
      { value: "PROXIMIDAD A PROVEEDORES", label: "PROXIMIDAD A PROVEEDORES" },
      { value: "OTRA ¿CUÁL?", label: "OTRA ¿CUÁL?" },
    ],
  },
  {
    type: "select",
    label: "Producto o servicio que comercializa",
    name: "product",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      {
        value: "VENTA DE PERIODICOS / REVISTAS",
        label: "VENTA DE PERIODICOS / REVISTAS",
      },
      { value: "VENTA DE LIBROS", label: "VENTA DE LIBROS" },
      {
        value: "VENTA DE ALIMENTOS PREPARADOS",
        label: "VENTA DE ALIMENTOS PREPARADOS",
      },
      { value: "VENTA DE FRUTAS/VERDURAS", label: "VENTA DE FRUTAS/VERDURAS" },
      { value: "VENTA DE MECATO", label: "VENTA DE MECATO" },
      { value: "VENTA DE HELADOS/PALETAS", label: "VENTA DE HELADOS/PALETAS" },
      {
        value: "VENTA DE DULCES / DULCES TIPICOS",
        label: "VENTA DE DULCES / DULCES TIPICOS",
      },
      { value: "VENTA DE CONFITERIA", label: "VENTA DE CONFITERIA" },
      { value: "VENTA DE MINUTOS", label: "VENTA DE MINUTOS" },
      {
        value: "VENTA DE CELULARES Y ACCESORIOS PARA CELULAR",
        label: "VENTA DE CELULARES Y ACCESORIOS PARA CELULAR",
      },
      {
        value: "SERVICIO DE MANTENIMIENTO DE CELULARES",
        label: "SERVICIO DE MANTENIMIENTO DE CELULARES",
      },
      {
        value: "VENTA DE ACCESORIOS PARA CELULAR",
        label: "VENTA DE ACCESORIOS PARA CELULAR",
      },
      { value: "VENTA DE ARTESANIAS", label: "VENTA DE ARTESANIAS" },
      {
        value: "VENTA DE RELOJERIA Y JOYERIA",
        label: "VENTA DE RELOJERIA Y JOYERIA",
      },
      {
        value: "SERVICIO DE REPARACIÓN DE RELOJERIA Y JOYERIA",
        label: "SERVICIO DE REPARACIÓN DE RELOJERIA Y JOYERIA",
      },
      {
        value: "ARTISTAS (MUSICA - TEATRO - OTRO)",
        label: "ARTISTAS (MUSICA - TEATRO - OTRO)",
      },
      { value: "VENTA DE CALZADO", label: "VENTA DE CALZADO" },
      {
        value: "SERVICIO DE REPARACION DE CALZADO Y AFINES",
        label: "SERVICIO DE REPARACION DE CALZADO Y AFINES",
      },
      { value: "VENTA DE ROPA", label: "VENTA DE ROPA" },
      {
        value: "SERVICIO DE PEINADO/TRENZADO",
        label: "SERVICIO DE PEINADO/TRENZADO",
      },
      {
        value: "SERVICIO DE MASAJES EN PLAYAS",
        label: "SERVICIO DE MASAJES EN PLAYAS",
      },
      {
        value: "VENTA DE ACCESORIOS PARA VEHICULOS",
        label: "VENTA DE ACCESORIOS PARA VEHICULOS",
      },
      { value: "OTRA ¿CUÁL?", label: "OTRA ¿CUÁL?" },
    ],
  },
  {
    type: "select",
    label: "Tiempo en el espacio público",
    name: "public_space_time",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "AÑOS", label: "AÑOS" },
      { value: "DIAS", label: "DIAS" },
    ],
  },
  {
    type: "number",
    label: "# dias o años",
    name: "amount-of-time",
    required: true,
  },
  {
    type: "select",
    label: "Se ausenta del espacio",
    name: "absent",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: true, label: "SI" },
      { value: false, label: "NO" },
    ],
  },
  {
    type: "select",
    label: "Motivo",
    name: "absent_reason",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "Alteraciones en salud", label: "Alteraciones en salud" },
      { value: "Trabajo formal", label: "Trabajo formal" },
      {
        value: "Enfermedad de algún familiar",
        label: "Enfermedad de algún familiar",
      },
      { value: "OTRA ¿CUÁL?", label: "OTRA ¿CUÁL?" },
    ],
  },
  {
    type: "select",
    label: "Alguien lo reemplaza en su lugar",
    name: "substitute",
    required: false,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: true, label: "SI" },
      { value: false, label: "NO" },
    ],
  },
  {
    type: "text",
    label: "Nombre de la persona que lo suple",
    name: "substitute_name",
    placeholder: "Nombre Completo",
    required: false,
  },
  {
    type: "select",
    label: "Tipo de Documento",
    name: "substitute_document_type",
    required: false,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "CC", label: "CC" },
      { value: "CE", label: "CE" },
      { value: "PAS", label: "PAS" },
      { value: "PEP", label: "PEP" },
    ],
  },
  {
    type: "number",
    placeholder: "Número de Documento",
    label: "Número de Documento",
    name: "substitute_document_number",
    required: false,
  },
  {
    type: "select",
    label: "Jornada en la que realiza su actividad",
    name: "operational_hours",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "DIURNA", label: "DIURNA" },
      { value: "NOCTURNA", label: "NOCTURNA" },
    ],
  },
  {
    type: "select",
    label: "Motivo por el que realiza su actividad",
    name: "job_reason",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      {
        value: "FALTA DE EMPLEO PERMANENTE",
        label: "FALTA DE EMPLEO PERMANENTE",
      },
      { value: "FALTA DE EMPLEO", label: "FALTA DE EMPLEO" },
      {
        value: "NO CONTAR CON ESTUDIOS BÁSICOS O PROFESIONALES",
        label: "NO CONTAR CON ESTUDIOS BÁSICOS O PROFESIONALES",
      },
      {
        value: "TRABAJO HEREDADO POR LA FAMILIA",
        label: "TRABAJO HEREDADO POR LA FAMILIA",
      },
    ],
  },
  {
    type: "file",
    label: "Carnet",
    name: "carnet",
    required: true,
  },
  {
    type: "file",
    label: "Foto de su negocio",
    name: "bussiness_picture",
    required: true,
  },
  {
    type: "text",
    label: "Promedio ventas mensuales",
    name: "average_sales",
    placeholder: "Dirección Permanente",
    required: false,
  },
  {
    type: "select",
    label: "Estado Civil",
    name: "marital_status",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "SOLTERO (A)", label: "SOLTERO (A)" },
      { value: "CASADO (A)", label: "CASADO (A)" },
      { value: "UNION LIBRE", label: "UNION LIBRE" },
    ],
  },
  {
    type: "title",
    label: "Información del Conyugue o Compañero Permanente",
    name: "partner_information",
  },
  {
    type: "text",
    label: "Nombre Completo del Conyuge",
    name: "partner_name",
    placeholder: "Nombre Completo",
    required: false,
  },
  {
    type: "select",
    label: "Tipo de Documento",
    name: "partner_document_type",
    required: false,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "CC", label: "CC" },
      { value: "CE", label: "CE" },
      { value: "PAS", label: "PAS" },
      { value: "PEP", label: "PEP" },
    ],
  },
  {
    type: "number",
    placeholder: "Número de Documento",
    label: "Número de Documento",
    name: "partner_document_number",
    required: false,
  },
  {
    type: "text",
    label: "Correo Electrónico",
    name: "partner_email",
    placeholder: "micorreo@proveedor.com",
    required: false,
  },
  {
    type: "text",
    label: "Teléfono o Celular",
    name: "partner_phone",
    placeholder: "Dirección Permanente",
    required: false,
  },
  {
    type: "number",
    placeholder: "No. de hijos",
    label: "No. de hijos",
    name: "number_of_children",
    required: true,
  },
  {
    type: "title",
    label: "Información del Hogar",
    name: "home_information",
  },
  {
    type: "text",
    label: "Otras Fuentes de Ingresos",
    name: "source_of_income",
    placeholder: "Otras Fuentes de Ingresos",
    required: true,
  },
  {
    type: "text",
    label: "Promedio ingresos mensuales del hogar",
    name: "average_source_of_income",
    placeholder: "Promedio de ingresos mensuales en pesos",
    required: true,
  },
  {
    type: "text",
    label: "Promedio egresos mensuales del hogar",
    name: "average_source_of_expenses",
    placeholder: "Promedio de egresos mensuales en pesos",
    required: true,
  },
  {
    type: "number",
    placeholder: "Personas a cargo",
    label: "Número de personas a cargo",
    name: "number_of_people_in_charge",
    required: true,
  },
  {
    type: "number",
    placeholder: "Número de personas de su núcleo familiar",
    label: "Número de personas de su núcleo familiar",
    name: "number_of_family_members",
    required: true,
  },
  {
    type: "select",
    label: "Pertenece a alguna agremiación",
    name: "is_associated",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: true, label: "SI" },
      { value: false, label: "NO" },
    ],
  },
  {
    type: "text",
    label: "¿Cuál?",
    name: "association",
    placeholder: "¿Cuál?",
    required: false,
  },
  {
    type: "title",
    label: "Información Laboral",
    name: "employee:info",
  },
  {
    type: "select",
    label: "Ha trabajado antes como empleado",
    name: "ex_employee",
    required: false,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: true, label: "SI" },
      { value: false, label: "NO" },
    ],
  },
  {
    type: "text",
    label: "¿En que empresa?",
    name: "company",
    placeholder: "Nombre de la empresa",
    required: false,
  },
  {
    type: "select",
    label: "Ha estado buscando trabajo el último año",
    name: "searching_for_job",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: true, label: "SI" },
      { value: false, label: "NO" },
    ],
  },
  {
    type: "select",
    label: "Nivel de Estudio",
    name: "level_of_study",
    required: true,
    options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "SIN ESTUDIO", label: "SIN ESTUDIO" },
      { value: "BACHILLER", label: "BACHILLER" },
      { value: "TECNICO/TECNOLOGO", label: "TECNICO/TECNOLOGO" },
      { value: "PROFESIONAL", label: "PROFESIONAL" },
      { value: "POSGRADUADO", label: "POSGRADUADO" },
    ],
  },
  {
    type: "text",
    label: "Tipo de Profesión",
    name: "Tipo de Profesión",
    placeholder: "Nombre de la empresa",
    required: true,
  },
];

export default step2;
