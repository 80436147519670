import axios from "axios";
import { config } from "../const/index";

export async function getDocuments(
  data: any,
  fileName: string,
  type: string,
  selectedPks: any
) {
  const auth: any = localStorage.getItem("auth");
  const token: any = JSON.parse(auth).access_token;
  var configGet = {
    headers: {
      Authorization: "Bearer " + token.replace(/['"]+/g, ""),
    },
  };
  try {
    let dataSelected = selectedPks.length > 0 ? selectedPks : null;
    const pathDownload =
      type === "excel"
        ? "/admin/export-users/excel"
        : "/admin/export-users/csv";
    const response = await axios.post(
      `${config.urlBase}${pathDownload}?${data}`,
      { users: dataSelected },
      configGet
    );

    console.log(response);
    const base64Data = response.data.result;
    const contentType = response.headers["content-type"];

    // Convertir el base64 a un objeto Blob
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });

    // Crear un objeto File a partir del Blob
    const file = new File([blob], fileName, { type: type === "excel"?"xlsx":"csv" });

    // Crear una URL de descarga y hacer clic en ella para iniciar la descarga
    const downloadUrl = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error("Error downloading document:", error);
  }
}
