import { call, put, takeEvery } from "redux-saga/effects";
import { loginService } from "../../services/loginService";
import * as type from "../types";

function getApi(data: any) {
  return loginService(data)
    .then((res) => res)
    .catch((error) => {
      console.log("login error: ", error);
      {
        throw error;
      }
    });
}

function* fetchLogin(action: any): any {
  try {
    const login = yield call(getApi, action.data);
    yield put({ type: type.POST_LOGIN_SUCCESS, login: login });
  } catch (e: any) {
    yield put({ type: type.POST_LOGIN_FAILED, message: e.message });
  }
}

function* loginSaga() {
  yield takeEvery(type.POST_LOGIN_REQUESTED, fetchLogin);
}

export default loginSaga;
