import * as type from "../types";
const initialState = {
  date: null,
  consulta: null,
  loading: false,
  error: null,
};

const getCurrentDateTime = (): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const currentDateTime: string = new Date().toLocaleString("en-US", options);

  return currentDateTime;
};

export default function consulta(state = initialState, action: any) {
  switch (action.type) {
    case type.POST_CONSULTA_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
        consulta: null,
        date: null,
      };
    case type.POST_CONSULTA_SUCCESS:
      return {
        ...state,
        loading: false,
        consulta: action.consulta.data.result,
        error: null,
        date: getCurrentDateTime(),
      };
    case type.POST_CONSULTA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
        consulta: null,
        date: null,
      };
    case "RESET_STATE-CONSULT":
      return initialState;
    default:
      return state;
      break;
  }
}
