import Index from "./routes/Index";
import "./app.scss";
import store from "./redux/store";
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Provider store={store}>
      <div className="ml-app">
        <Index />
      </div>
    </Provider>
  );
}

export default App;
