import axios from "axios";
import { config } from "../const/index";

export function itemDescription(value: string) {
  return axios.get(`${config.urlBase}items/${value}`);
}

export function itemDescriptiontext(value: string) {
  return axios.get(`${config.urlBase}items/${value}/description`);
}

