const step3 = [
  {
    type: "multiselect",
    label: "Grupo poblacional",
    name: "population_group",
    multiple :true,
    required: true,
   options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "PERSONA CON DISCAPACIDAD", label: "PERSONA CON DISCAPACIDAD" },
      { value: "MADRE CABEZA DE HOGAR", label: "MADRE CABEZA DE HOGAR" },
      { value: "DESPLAZADO...", label: "DESPLAZADO..." },
      { value: "POBLACIÓN LGBTIQ+", label: "POBLACIÓN LGBTIQ+" },
      { value: "ADULTO MAYOR", label: "ADULTO MAYOR" },
      {
        value:
          "POBLACION GITANA O NARP (NEGRITUDES, AFROCOLOMBIANOS, RAIZALES, PALENQUERAS)",
        label:
          "POBLACION GITANA O NARP (NEGRITUDES, AFROCOLOMBIANOS, RAIZALES, PALENQUERAS)",
      },
      { value: "NINGUNA", label: "NINGUNA" },
      { value: "OTRA ¿CUÁL?", label: "OTRA ¿CUÁL?" },
    ],
  },
  {
    type: "select",
    label: "Pertenece a alguna etnia",
    name: "ethnicity",
    required: true,
   options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "AFROCOLOMBIANO", label: "AFROCOLOMBIANO" },
      { value: "COMUNIDAD NEGRA", label: "COMUNIDAD NEGRA" },
      { value: "PALENQUERO", label: "PALENQUERO" },
      { value: "INDIGENA", label: "INDIGENA" },
      { value: "NINGUNA", label: "NINGUNA" },
    ],
  },
  {
    type: "select",
    label: "Sistema de Seguridad Social en salud",
    name: "social_security",
    required: true,
   options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      {
        value: "AFILIADO A REGIMEN ESPECIAL",
        label: "AFILIADO A REGIMEN ESPECIAL",
      },
      {
        value: "AFILIADO A REGIMEN CONTRIBUTIVO",
        label: "AFILIADO A REGIMEN CONTRIBUTIVO",
      },
      {
        value: "AFILIADO A REGIMEN SUBSIDIADO",
        label: "AFILIADO A REGIMEN SUBSIDIADO",
      },
      { value: "NO AFILIADO", label: "NO AFILIADO" },
    ],
  },
  {
    type: "select",
    label: "Categoría Sisben",
    name: "category",
    required: true,
   options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      {
        value: "GRUPO A",
        label: "GRUPO A (A1-A5)",
      },
      {
        value: "GRUPO B",
        label: "GRUPO B (B1-B7)",
      },
      {
        value: "GRUPO C",
        label: "GRUPO C (C1-C18)",
      },
      {
        value: "GRUPO D",
        label: "GRUPO D (D1-D21)",
      },
    ],
  },
  {
    type: "text",
    label: "Nombre de la E.P.S.",
    name: "eps",
    placeholder: "Nombre de la empresa",
    required: true,
  },
  {
    type: "select",
    label: "Presenta alguna discapacidad",
    name: "disability",
    required: true,
   options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "NO", label: "NO" },
      { value: "AUDITIVA", label: "AUDITIVA" },
      { value: "VISUAL", label: "VISUAL" },
      { value: "MOVLIDAD REDUCIDA", label: "MOVLIDAD REDUCIDA" },
      { value: "MULTIPLE", label: "MULTIPLE" },
      { value: "INTELECTUAL", label: "INTELECTUAL" },
      {
        value: "SENSORIAL (GUSTO, OLFATO, TACTO)",
        label: "SENSORIAL (GUSTO, OLFATO, TACTO)",
      },
      { value: "SORDOCEGUERA", label: "SORDOCEGUERA" },
      { value: "OTRA ¿CUÁL?", label: "OTRA ¿CUÁL?" },
    ],
  },
  {
    type: "select",
    label: "¿Ha tenido algún tipo de antecedente legal?",
    name: "background",
    required: true,
   options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: true, label: "SI" },
      { value: false, label: "NO" },
    ],
  },
  {
    type: "select",
    label: "Recibe algún subsidio del estado",
    name: "subsidy_beneficiary",
    required: true,
   options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: true, label: "SI" },
      { value: false, label: "NO" },
    ],
  },
  {
    type: "select",
    label: "¿Cuál?",
    name: "subsidy",
    required: false,
   options: [
      {
        value: null,
        label: "SELECCIONE UNO",
      },
      { value: "COLOMBIA MAYOR", label: "COLOMBIA MAYOR" },
      { value: "TRANSITO A RENTA CIUDADANA", label: "TRANSITO A RENTA CIUDADANA" },
      { value: "JÓVENES EN ACCION", label: "JÓVENES EN ACCION" },
    ],
  },
];

export default step3;
