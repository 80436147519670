import { createStore, compose,applyMiddleware } from 'redux';
import rootReducer from "./reducers/index"
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './sagas/index';

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware];
const store =createStore(rootReducer,applyMiddleware(...middlewares))

sagaMiddleware.run(rootSaga)

export default store;