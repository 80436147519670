import React, { useEffect } from "react";
import { Facebook, Instagram } from "../../components";

const test: React.FC = () => {


  return (
    <div>
      <Instagram />

    </div>
  );
};

export default test;