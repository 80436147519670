import { call, put, takeEvery } from "redux-saga/effects";
import { registroService } from "../../services/registroService";
import * as type from "../types";

function getApi(data: any) {
  return registroService(data)
    .then((res) => res)
    .catch((error) => {
      console.log("registro error: ", error);
      {
        throw error;
      }
    });
}

function* fetchRegistro(action: any): any {
  try {
    const registro = yield call(getApi, action.data);
    yield put({ type: type.POST_REGISTRO_SUCCESS, registro: registro });
  } catch (e: any) {
    yield put({ type: type.POST_REGISTRO_FAILED, message: e.message });
  }
}

function* registroSaga() {
  yield takeEvery(type.POST_REGISTRO_REQUESTED, fetchRegistro);
}

export default registroSaga;
