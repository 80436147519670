import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import "./Table.scss";
import Paginador from "../Paginador/Paginador";
import TableActions from "../TableActions/TableActions";

interface Action {
  title: string;
  deleteElement: (e: any) => void;
  edit: (e: any) => void;
}

interface Data {
  titles: { title: string; key: string }[];
  info: { [key: string]: any; badgeColor?: any; checked?: boolean }[][];
  actions?: Action;
}

interface TablaProps {
  data: Data;
  itemsPerPage?: number;
  desde: any;
  hasta: any;
  setDesde: (value: string) => void;
  setHasta: (value: string) => void;
  getTabla: () => void;
  downLoadExcel: () => void;
  downLoadcsv: () => void;
  setDataTable: any
}

const Tabla: React.FC<TablaProps> = ({
  data,
  setDesde,
  setHasta,
  getTabla,
  downLoadcsv,
  downLoadExcel,
  desde,
  hasta,
  setDataTable
}) => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowIndices, setSelectedRowIndices] = useState<number[]>([]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const totalPages = Math.ceil(data.info.length / itemsPerPage);

  const selectAllRows = () => {
    const updatedData = { ...data };

    data.info.forEach((el:any,index:any) => {
      updatedData.info[index][9].checked = false;
    });
    setDataTable(updatedData);
  };

  const deselectAllRows = () => {
    const updatedData = { ...data };

    data.info.forEach((el:any,index:any) => {
      updatedData.info[index][9].checked = true;
    });
    setDataTable(updatedData);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleCellClick = (rowIndex: number) => {
    const updatedData = { ...data };
    const checked = !updatedData.info[rowIndex][9]?.checked;
    updatedData.info[rowIndex][9].checked = checked;
    setDataTable(updatedData);
    // if (selectedRowIndices.includes(rowIndex)) {
    //   setSelectedRowIndices(selectedRowIndices.filter((index) => index !== rowIndex));
    // } else {
    //   setSelectedRowIndices([...selectedRowIndices, rowIndex]);
    // }
  };

  useEffect(() => {
    console.log("Filas seleccionadas:", selectedRowIndices);
  }, [selectedRowIndices]);

  const handleIcon = (rowIndex: number) => {
    const checked = data.info[rowIndex][9]?.checked;
    return checked ? faCheckSquare : faSquare;
  };

  return (
    <div className="table-container">
      <TableActions
        setItemsPerPage={setItemsPerPage}
        desde={desde}
        hasta={hasta}
        setDesde={setDesde}
        setHasta={setHasta}
        getTabla={getTabla}
        downLoadExcel={downLoadExcel}
        downLoadcsv={downLoadcsv}
        selectAllRows={selectAllRows}
        deselectAllRows={deselectAllRows}
        data={data}
      />
      <table className="rb-table">
        <thead className="rb-thead">
          <tr>
            <th></th>
            {data.titles.map((title) => (
              <th key={title.key}>{title.title}</th>
            ))}
            {data.actions && <th>{data.actions.title}</th>}
          </tr>
        </thead>
        <tbody>
          {data.info.slice(startIndex, endIndex).map((row, rowIndex) => {
            const isSelected = selectedRowIndices.includes(rowIndex);

            return (
              <tr
                key={rowIndex}
                className={`rb-table-row ${isSelected ? "selected-row" : ""}`}
              >
                <td>
                  <FontAwesomeIcon
                    icon={handleIcon(rowIndex)}
                    onClick={() => handleCellClick(rowIndex)}
                    className="action-icon action-icon-checkbox"
                  />
                </td>

                {row.map((cell, cellIndex) => {
                  if (data.titles[cellIndex]?.key) {
                    const columnKey = data.titles[cellIndex]?.key;
                    const badgeColor = cell.badgeColor || "";
                    const cellValue = cell[columnKey];
                    return (
                      <td
                        key={cellIndex}
                        className={`rb-${cellIndex + 1} rb-table-cell`}
                      >
                        {badgeColor ? (
                          <span className={`badge bg-${badgeColor}`}>
                            {cellValue}
                          </span>
                        ) : (
                          cellValue
                        )}
                      </td>
                    );
                  }
                  return null;
                })}
                {data.actions && (
                  <td>
                    {data.actions.edit && (
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => data.actions?.edit(row)}
                        className="action-icon action-icon-edit"
                      />
                    )}
                    {data.actions.deleteElement && (
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        onClick={() => data.actions?.deleteElement(row)}
                        className="action-icon action-icon-delete"
                      />
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="rb-paginador-container">
        {data.info.length > itemsPerPage && (
          <Paginador
            currentPage={currentPage}
            totalPages={totalPages}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handleFirstPage={handleFirstPage}
            handleLastPage={handleLastPage}
          />
        )}
      </div>
    </div>
  );
};

export default Tabla;
