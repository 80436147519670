import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import "./Delete.scss"


function ModalDelete({ modal, setModal, deletePetition,form,setForm }: any) {
    // const [modal, setModal] = useState(false);
    // const success = true
    // const text = "El número de documento 0.000.000.000 NO SE ENCUENTRA REGISTRADO en el sistema de consulta y registro de vendedores informales."
    // const text = "su registro en el sistema de consulta y registro de vendedores informales se ha realizado de manera EXITOSA."


    const toggle = () => setModal(!modal);

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>
        Click Me
      </Button> */}
            <Modal isOpen={modal} toggle={toggle} centered size="lg">
                <ModalBody>
                    <div className="rb-edit-state">
                        <br/>
                        <div>¿Está seguro de que desea eliminar este elemento? </div>
                        <br/>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { setModal(false) }}>Cancelar</Button>
                    <Button onClick={() => { deletePetition() }}>Eliminar</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ModalDelete;
