import * as type from '../types'
const initialState = {
    list: [],
    loading: false,
    error: null
}

export default function list(state = initialState, action:any) {
    switch (action.type) {
        case type.GET_LIST_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.list.data.results
            }
        case type.GET_LIST_FAILED:
            return { 
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state;
            break;
    }
}