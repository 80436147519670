import { useSelector, useDispatch } from 'react-redux';
import { faBars, faInfo, faDollar, faPerson } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { Footer, Header, Inputs } from "../../components";
import Stepper from "../../components/Stepper/Stepper";
import { step1, step2, step3, text } from "../../const";
import "./Registro.scss"
import { postRegistro, resetStateRegistro } from '../../redux/actions/registro';
import ModalRB from '../../components/Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Registro() {
  const [form1, setForm1] = useState<any>({});
  const [form2, setForm2] = useState<any>({});
  const [form3, setForm3] = useState<any>({});
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [success, setSucces] = useState(false);
  const [firstUpdate, setFirstUpdate] = useState(true);
  const [exclude, setExclude] = useState<string[]>([]);
  const [resetAllform, setResetAllform] = useState(false);

  const dispatch = useDispatch()
  const registro = useSelector((state: any) => state.registro)


  const [step, setStep] = useState(0)
  const navigate = useNavigate();
  const steps = [
    { icon: <FontAwesomeIcon className="icon-wizzard" icon={faInfo} />, title: 'Información General', subtitle: 'del vendedor informal' },
    { icon: <FontAwesomeIcon className="icon-wizzard" icon={faDollar} />, title: 'Información Socioeconómica', subtitle: 'del vendedor informal' },
    { icon: <FontAwesomeIcon className="icon-wizzard" icon={faPerson} />, title: 'Información Psicosocial', subtitle: 'del vendedor informal' },
    // { icon: <FontAwesomeIcon className="icon-wizzard" icon={faBars}/>, title: 'Title 4', subtitle: 'Subtitle 4' },
  ];

  // Utilizar useEffect para establecer la fecha actual una vez al cargar el componente
  useEffect(() => {

    const today = new Date();
    const year = today.getFullYear().toString();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');

    // Formatear la fecha en el formato YYYY-MM-DD requerido por el input tipo date
    const formattedDate = `${year}-${month}-${day}`;

    // Establecer la fecha actual en el estado de form1
    setForm1({ ...form1, user_date: formattedDate });

    // Actualizar el valor en el input HTML
    const inputDate: HTMLInputElement | null = document.querySelector('[name="user_date"]');
    if (inputDate) {
      inputDate.value = formattedDate;
    }
  }, []);

  const deleteString = (string: string) => {
    const updatedExclude = exclude.filter((item) => item !== string);
    setExclude(updatedExclude);
  };


  function removeDuplicates(array: string[]): string[] {
    const uniqueArray: string[] = [];

    array.forEach((item) => {
      if (!uniqueArray.some((uniqueItem) => uniqueItem === item)) {
        uniqueArray.push(item);
      }
    });

    return uniqueArray;
  }

  useEffect(() => { window.scrollTo(0, 0); }, [])

  useEffect(() => {
    const temporalExclude: any = exclude
    const handleDisplayChange = () => {
      if (form2.absent === "false") {
        const absent_reason: any = document.querySelector<HTMLInputElement>('#absent_reason');
        const substitute: any = document.querySelector<HTMLInputElement>('#substitute');
        const substitute_name: any = document.querySelector<HTMLInputElement>('#substitute_name');
        const substitute_document_type: any = document.querySelector<HTMLInputElement>('#substitute_document_type');
        const substitute_document_number: any = document.querySelector<HTMLInputElement>('#substitute_document_number');
        substitute_name.style.display = "none";
        substitute_document_type.style.display = "none";
        substitute_document_number.style.display = "none";
        absent_reason.style.display = "none";
        substitute.style.display = "none";
        delete form2.substitute_name;
        delete form2.substitute_document_type;
        delete form2.substitute_document_number;
        delete form2.absent_reason;
        delete form2.substitute;
        // temporalExclude.push("")
        temporalExclude.push("substitute_name")
        temporalExclude.push("substitute_document_type")
        temporalExclude.push("substitute_document_number")
        temporalExclude.push("absent_reason")
        temporalExclude.push("substitute")


      } else {
        const absent_reason: any = document.querySelector<HTMLInputElement>('#absent_reason');
        const substitute: any = document.querySelector<HTMLInputElement>('#substitute');
        if (form2.substitute === "false") {
          const substitute_name: any = document.querySelector<HTMLInputElement>('#substitute_name');
          const substitute_document_type: any = document.querySelector<HTMLInputElement>('#substitute_document_type');
          const substitute_document_number: any = document.querySelector<HTMLInputElement>('#substitute_document_number');
          substitute_name.style.display = "none";
          substitute_document_type.style.display = "none";
          substitute_document_number.style.display = "none";
          delete form2.substitute_name;
          delete form2.substitute_document_type;
          delete form2.substitute_document_number;
          temporalExclude.push("substitute_name")
          temporalExclude.push("substitute_document_type")
          temporalExclude.push("substitute_document_number")
        } else {
          const substitute_name: any = document.querySelector<HTMLInputElement>('#substitute_name');
          const substitute_document_type: any = document.querySelector<HTMLInputElement>('#substitute_document_type');
          const substitute_document_number: any = document.querySelector<HTMLInputElement>('#substitute_document_number');
          substitute_name.style.display = "inherit";
          substitute_document_type.style.display = "inherit";
          substitute_document_number.style.display = "inherit";
          deleteString("substitute_name")
          deleteString("substitute_document_type")
          deleteString("substitute_document_number")
        }
        absent_reason.style.display = "inherit";
        substitute.style.display = "inherit";

      }
      if (form2.seller_type === "VENDEDOR INFORMAL AMBULANTE") {
        const public_space_address: any = document.querySelector<HTMLInputElement>('#public_space_address');
        public_space_address.style.display = "none";
        delete form2.public_space_address;
        temporalExclude.push("public_space_address")
      } else {
        const public_space_address: any = document.querySelector<HTMLInputElement>('#public_space_address');
        public_space_address.style.display = "inherit";
        deleteString("public_space_address")
      }
      if (form2.marital_status === "SOLTERO (A)") {
        const partner_name: any = document.querySelector<HTMLInputElement>('#partner_name')
        const partner_information: any = document.querySelector<HTMLInputElement>('#partner_information')
        const partner_document_type: any = document.querySelector<HTMLInputElement>('#partner_document_type')
        const partner_document_number: any = document.querySelector<HTMLInputElement>('#partner_document_number')
        const partner_email: any = document.querySelector<HTMLInputElement>('#partner_email')
        const partner_phone: any = document.querySelector<HTMLInputElement>('#partner_phone')
        // const partner_document: any = document.querySelector<HTMLInputElement>('#partner_document')
        const number_of_children: any = document.querySelector<HTMLInputElement>('#number_of_children')
        partner_name.style.display = "none";
        partner_information.style.display = "none";
        partner_document_type.style.display = "none";
        partner_document_number.style.display = "none";
        partner_email.style.display = "none";
        partner_phone.style.display = "none";
        // partner_document.style.display = "none";
        number_of_children.style.display = "none";
        delete form2.partner_name;
        delete form2.partner_document_type;
        delete form2.partner_document_number;
        delete form2.partner_email;
        delete form2.partner_phone;
        // delete form2.partner_document;
        delete form2.number_of_children;
        temporalExclude.push("partner_name")
        temporalExclude.push("partner_document_type")
        temporalExclude.push("partner_document_number")
        temporalExclude.push("partner_email")
        temporalExclude.push("partner_phone")
        // temporalExclude.push("partner_document")
        temporalExclude.push("number_of_children")

      } else {
        const partner_name: any = document.querySelector<HTMLInputElement>('#partner_name')
        const partner_information: any = document.querySelector<HTMLInputElement>('#partner_information')
        const partner_document_type: any = document.querySelector<HTMLInputElement>('#partner_document_type')
        const partner_document_number: any = document.querySelector<HTMLInputElement>('#partner_document_number')
        const partner_email: any = document.querySelector<HTMLInputElement>('#partner_email')
        const partner_phone: any = document.querySelector<HTMLInputElement>('#partner_phone')
        // const partner_document: any = document.querySelector<HTMLInputElement>('#partner_document')
        const number_of_children: any = document.querySelector<HTMLInputElement>('#number_of_children')
        partner_name.style.display = "inherit";
        partner_information.style.display = "flex";
        partner_document_type.style.display = "inherit";
        partner_document_number.style.display = "inherit";
        partner_email.style.display = "inherit";
        partner_phone.style.display = "inherit";
        // partner_document.style.display = "inherit";
        number_of_children.style.display = "inherit";
        deleteString("partner_name")
        deleteString("partner_document_type")
        deleteString("partner_document_number")
        deleteString("partner_email")
        deleteString("partner_phone")
        // deleteString("partner_document")
        deleteString("number_of_children")
      }
      if (form2.is_associated === "true" || form2.is_associated === true || form2.is_associated === undefined) {
        const association: any = document.querySelector<HTMLInputElement>('#association');
        association.style.display = "inherit";
        deleteString("association")
      } else {
        const association: any = document.querySelector<HTMLInputElement>('#association');
        association.style.display = "none";
        delete form2.association;
        temporalExclude.push("association")

      }

      if (form2.ex_employee === "true" || form2.ex_employee === true || form2.ex_employee === undefined) {
        const company: any = document.querySelector<HTMLInputElement>('#company');
        company.style.display = "inherit";
        deleteString("company")
      } else {
        const company: any = document.querySelector<HTMLInputElement>('#company');
        company.style.display = "none";
        delete form2.company;
        temporalExclude.push("company")

      }

      setExclude(removeDuplicates(temporalExclude))
    };
    if (step === 1) {
      handleDisplayChange();

    }
  }, [form2]);

  useEffect(() => {
    const temporalExclude: any = exclude
    if (form1.sex === "OTRO") {
      const which: any = document.querySelector<HTMLInputElement>('#which');
      which.style.display = "inherit";
      deleteString("which")

    } else {
      const which: any = document.querySelector<HTMLInputElement>('#which');
      /// quitar el if 
      if (which) {
        which.style.display = "none";
        delete form1.which;
        temporalExclude.push("which")
      }

    }
    setExclude(removeDuplicates(temporalExclude))
  }, [form1])

  useEffect(() => {
    const temporalExclude: any = exclude
    const handleDisplayChange = () => {
      if (form3.social_security === "NO AFILIADO") {
        const category: any = document.querySelector<HTMLInputElement>('#category');
        const eps: any = document.querySelector<HTMLInputElement>('#eps');

        category.style.display = "none";
        eps.style.display = "none";
        delete form3.category;
        delete form3.eps;
        temporalExclude.push("category")
        temporalExclude.push("eps")
      } else {
        const category: any = document.querySelector<HTMLInputElement>('#category');
        const eps: any = document.querySelector<HTMLInputElement>('#category');
        category.style.display = "inherit";
        eps.style.display = "inherit";
        deleteString("category")
        deleteString("eps")
      };
      if (form3.subsidy_beneficiary === "true" || form3.subsidy_beneficiary === true || form3.subsidy_beneficiary === undefined) {
        const subsidy: any = document.querySelector<HTMLInputElement>('#subsidy');
        subsidy.style.display = "inherit";
        deleteString("subsidy")
      } else {
        const subsidy: any = document.querySelector<HTMLInputElement>('#subsidy');
        subsidy.style.display = "none";
        delete form3.subsidy;
        console.log(form3.subsidy_beneficiary)

        temporalExclude.push("subsidy")
      };
      const isPopulationGroupPresent = form3.population_group?.some((item: any) => item === "POBLACION GITANA O NARP (NEGRITUDES, AFROCOLOMBIANOS, RAIZALES, PALENQUERAS)");

      if (!isPopulationGroupPresent) {
        const ethnicity: any = document.querySelector<HTMLInputElement>('#ethnicity');

        ethnicity.style.display = "none";
        delete form3.ethnicity;
        temporalExclude.push("ethnicity")
      } else {
        const ethnicity: any = document.querySelector<HTMLInputElement>('#ethnicity');
        ethnicity.style.display = "inherit";
        deleteString("ethnicity")
      };
      setExclude(removeDuplicates(temporalExclude))
    }



    if (step === 2) {
      handleDisplayChange();
    }
  }, [form3]);


  useEffect(() => {
    if (firstUpdate) {
      setForm1(selectAssinger(step1))
      setForm2(selectAssinger(step2))
      setForm3(selectAssinger(step3))
      setFirstUpdate(false)
    }
  }, [firstUpdate])

  const selectAssinger = (stepInfo: any) => {
    let options: any = {}
    stepInfo.forEach((element: any) => {
      if (element.type === "select") {
        options[element.name] = element.options[0].value
      }
    })
    return options
  }

  const validateKey = (element: string): boolean => {
    return exclude.includes(element);
  };

  const requiredValidator = (stepInfo: any, formactive: any) => {
    let validator: boolean = true
    stepInfo.forEach((element: any) => {
      if (element.required === true && !validateKey(element.name)) {

        if (!formactive[element.name] || formactive[element.name] === undefined) {
          validator = false
        }
      }
    })
    return validator
  }

  const stepperControler = () => {
    switch (step) {
      case 0:
        if (requiredValidator(step1, form1)) {
          // console.log(form1)
          const today = new Date();
          const year = today.getFullYear().toString();
          const month = (today.getMonth() + 1).toString().padStart(2, '0');
          const day = today.getDate().toString().padStart(2, '0');

          // Formatear la fecha en el formato YYYY-MM-DD requerido por el input tipo date
          const formattedDate = `${year}-${month}-${day}`;

          // Establecer la fecha actual en el estado de form1
          setForm1({ ...form1, user_date: formattedDate });

          // Actualizar el valor en el input HTML
          const inputDate: HTMLInputElement | null = document.querySelector('[name="user_date"]');
          if (inputDate) {
            inputDate.value = formattedDate;
          }
          setStep(step + 1)
        } else {
          setModalError(true)
          console.log(form1)
        }
        break;
      case 1:
        if (requiredValidator(step2, form2)) {
          setStep(step + 1)
        } else {
          setModalError(true)
          console.log(form1)
          console.log(form2)
        }
        break;
      case 2:
        if (requiredValidator(step3, form3)) {
          if (form1.which) {
            form1.sex = form1.which;
          }
          setResetAllform(false)
          console.log("ppppppppppppppppppppppp2");

          dispatch(postRegistro({ ...form1, ...form2, ...form3 }))
        } else {
          setModalError(true)
          console.log(form3)
        }
        break;
      default:
        break;
    }
  }


  useEffect(() => {
    if (registro.registro) {
      setModal(true);
      setSucces(true);
      // dispatch(postRegistro(resetStateRegistro()))
      setForm1({})
      setForm2({})
      setForm3({})
      setStep(0)
      setForm1(selectAssinger(step1))
      setForm2(selectAssinger(step2))
      setForm3(selectAssinger(step3))
      console.log("ppppppppppppppppppppppp");
      setResetAllform(true)
    }
  }, [registro])


  return (
    <>
      <ModalRB modal={modal} setModal={setModal} text={text.successText} success={success} />
      <ModalRB modal={modalError} setModal={setModalError} text={"Por favor diligencie todos los campos obligatorios (*)"} success={false} />
      <div className="rb-register-container">
        <Header color="secondary" />
        <Container>
          <div className='row'>
            <div className='col-12'>
              <div className="rb-register">
                <div className={`rb-card rb-card-register`}>
                  <div className='rb-register-text'><div className='rb-register-text-title'>{text.tituloFormulario}</div><div className='rb-register-text-content'>{text.formularioTexto}</div></div>

                  {!resetAllform ? (<>
                    <div className="rb-register-stepper">
                      <Stepper steps={steps} activeStep={step} setStep={setStep} />
                    </div>
                    <div className="rb-register-input-container">
                      <>     <div className={step === 0 ? "view-inputs" : "hide-inputs"}><Inputs inputs={step1} form={form1} setForm={setForm1} /></div>
                        <div className={step === 1 ? "view-inputs" : "hide-inputs"}> <Inputs inputs={step2} form={form2} setForm={setForm2} /></div>
                        <div className={step === 2 ? "view-inputs" : "hide-inputs"}><Inputs inputs={step3} form={form3} setForm={setForm3} /></div></>

                    </div>
                    <div className={`rb-button-container ${step === steps.length - 1 ? "rb-button-container-flex-center" : "rb-button-container-flex-end"}`}>
                      <button onClick={() => stepperControler()} className="rb-button rb-button-next">{step === steps.length - 1 ? text.endRegister : text.next}</button>
                    </div></>) : <div className={`rb-button-container rb-button-container-flex-center}`}>
                    <button onClick={() => window.location.reload()} className="rb-button rb-button-next">Realizar un nuevo registro</button>
                  </div>}

                </div>
              </div>
            </div>
          </div>
        </Container>

        <Footer color="secondary" />
      </div>
    </>
  );
}

export default Registro;
