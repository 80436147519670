import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import errorImg from "../../assets/images/Recurso1Validador.svg";
import succesImg from "../../assets/images/Recurso2Validador.svg";
import "./Modal.scss";

function ModalRB({ modal, setModal, success, text, onlyText }: any) {
  const toggle = () => setModal(!modal);

  return (
    <div className="rb-div-modal-container">
      <Modal isOpen={modal} toggle={toggle} centered size="lg" className="rb-div-modal-container">
        <ModalBody>
          <div className="rb-modal-content">
            {!onlyText && (
              <img className="rb-modal-img" src={success ? succesImg : errorImg} alt="mi imagen" />
            )}
            <div className="rb-modal-text" dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br>') }} />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ModalRB;
