import * as type from '../types'

const initialState = {
    login: [],
    loading: false,
    error: null
}

export default function Login(state = initialState, action:any) {
    switch (action.type) {
        case type.POST_LOGIN_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.POST_LOGIN_SUCCESS:
            console.log(action.login.data.result)
            localStorage.setItem('auth', JSON.stringify(action.login.data.result));
            console.log("fffffffffffffffffffff");
            window.location.href = '/resultado'
            return {
                ...state,
                loading: false,
                // login: action.login.data.result
            }
        case type.POST_LOGIN_FAILED:
            return { 
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state;
            break;
    }
}