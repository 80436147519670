import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import "./Edit.scss"


function Modaledit({ modal, setModal, editPetition,form,setForm }: any) {
    // const [modal, setModal] = useState(false);
    // const success = true
    // const text = "El número de documento 0.000.000.000 NO SE ENCUENTRA REGISTRADO en el sistema de consulta y registro de vendedores informales."
    // const text = "su registro en el sistema de consulta y registro de vendedores informales se ha realizado de manera EXITOSA."


    const toggle = () => setModal(!modal);

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>
        Click Me
      </Button> */}
            <Modal isOpen={modal} toggle={toggle} centered size="lg">
                <ModalBody>
                    <div className="rb-edit-state">

                        <div>Seleccione el nuevo estado : </div>

                        <select
                            className="rb-inputs rb-inputs-select"
                            value={form}
                            onChange={(e)=>{setForm(e.target.value)}}
                        >
                            <option value="APROBADO">
                                APROBADO
                            </option>
                            <option value="PENDIENTE">
                                PENDIENTE
                            </option>
                            <option value="DENEGADO">
                                DENEGADO
                            </option>
                        </select>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { setModal(false) }}>Cancelar</Button>
                    <Button onClick={() => { editPetition() }}>Editar</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default Modaledit;
