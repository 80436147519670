import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";
import { Footer, Header } from "../../components";
import { text } from "../../const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import "./ConsultaExitosa.scss"
import { resetStateConsults } from "../../redux/actions/consulta";
import { getDocumentConsulta } from "../../services/getDocumentConsulta";

const getCurrentDateTime = (): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  const currentDateTime: string = new Date().toLocaleString('en-US', options);


  return currentDateTime;
};

function ConsultaExitosa() {
  const consulta = useSelector((state: any) => state.consulta.consulta)
  const date = useSelector((state: any) => state.consulta.date)
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    console.log(consulta);
    if (!consulta) {
      navigate('/consulta')
    }
  }, [])

  useEffect(()=>{ window.scrollTo(0, 0);},[])
  return (
    <>
      {consulta && <div className="rb-consultaExitosa-container rb-page-container">
        <Header color="secondary" />
        <div className="rb-consultaExitosa">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
              <div className={`rb-card rb-card-consultaExitosa`}>
                <div className="rb-consultaExitosa-text-container" >
                  <div className="rb-consultaExitosa-text rb-consultaExitosa-titulo ">{text.consultaExitosaTitulo}</div>
                  <div className="rb-consultaExitosa-text rb-consultaExitosa-info1 ">{`El número de documento ${consulta.document_number} se encuentra `}<b>REGISTRADO</b> en el sistema de consulta y registro vendedores informales como vendedor informal de <b>{`${consulta.profession}`}</b>, el estado actual del registro es <b>{`${consulta.status}`}</b></div>
                  <div className="rb-consultaExitosa-text rb-consultaExitosa-info2 ">{`*Fecha de consulta: ${date}`}</div>
                  <div className="row">
                    <div className="col-sm-12">
                      <button className="rb-button rb-consultaExitosa-button rb-button-center" onClick={() => { dispatch(resetStateConsults()); navigate('/consulta') }}>Volver a inicio</button>
                      <button className="rb-button rb-consultaExitosa-button rb-button-center" onClick={()=>getDocumentConsulta({document_type:consulta.document_type,number:consulta.document_number},"ConsultaExitosa.pdf")}>Imprimir <FontAwesomeIcon icon={faPrint} /></button>
                    </div>
                  </div>
                  <div className="rb-consultaExitosa-text rb-consultaExitosa-info3 ">{text.consultaExitosainfo3}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer color="secondary" />
      </div>
      }
    </>
  );
}

export default ConsultaExitosa;
