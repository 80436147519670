import { call, put, takeEvery } from "redux-saga/effects";
import {
  itemDescription,
  itemDescriptiontext,
} from "../../services/itemDescription";
import * as type from "../types";

function getApi(id: string) {
  return itemDescription(id)
    .then((res) => res)
    .catch((error) => {
      console.log("decription error: ", error);
      {
        throw error;
      }
    });
}

function getApiText(id: string) {
  return itemDescriptiontext(id)
    .then((res) => res)
    .catch((error) => {
      console.log("decription error: ", error);
      {
        throw error;
      }
    });
}

function* fetchDescriotion(action: any): any {
  try {
    const description = yield call(getApi, action.id);
    const descriptionText = yield call(getApiText, action.id);
    yield put({
      type: type.GET_DESCRIPTION_SUCCESS,
      description: { description, descriptionText },
    });
  } catch (e: any) {
    yield put({ type: type.GET_DESCRIPTION_FAILED, message: e.message });
  }
}

function* productsSaga() {
  yield takeEvery(type.GET_DESCRIPTION_REQUESTED, fetchDescriotion);
}

export default productsSaga;
