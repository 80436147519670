import imageLogo from "../../assets/images/Logo_RUVI_azul.svg"
import imageLogoNormal from "../../assets/images/Logo_RUVI.svg"
import { faBars, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import "./Header.scss"

function Header({ color }: any) {
    const navigate = useNavigate();
    return (<div className="rb-header-container">
        <div className={`rb-header-home ${color === "secondary" ? "rb-header-home-secondary" : ""}`}>
            <FontAwesomeIcon icon={faHouse} onClick={() => { navigate('/') }} />
        </div>
        <div className="rb-logo">
            {color === "secondary" ? <img
                className="rb-image-header"
                src={imageLogoNormal}
                alt="mi imagen"
            /> : <img
                className="rb-image-header"
                src={imageLogo}
                alt="mi imagen"
            />}

        </div>
        <div className={`rb-menu ${color === "secondary" ? "rb-menu-secondary" : ""}`}>
            <FontAwesomeIcon icon={faBars} />
        </div>
    </div>
    );
}

export default Header;
