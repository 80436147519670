import * as types from "../types"

export function postRegistro(data:any){
    return {
        type: types.POST_REGISTRO_REQUESTED,
        data
    }
}

export const resetStateRegistro = () => {
    return {
      type: 'RESET_STATE_REGISTRO'
    };
  };