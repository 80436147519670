import { all } from 'redux-saga/effects'
import descriptionSaga from './descriptionSaga'
import productsSaga from './productsSaga'
import registroSaga from './registroSaga'
import loginSaga from './loginSaga'
import listSaga from './loginSaga'
import consultaSaga from './consultaSaga'

export default function* rootSaga() {
    yield all([productsSaga(),descriptionSaga(),registroSaga(),loginSaga(),loginSaga(),consultaSaga()])
}