const text = {
    textBanner:`El registro único de vendedores informales – RUVI, es una plataforma  modular ideada con la finalidad de lograr el registro de los vendedores informales del  distrito de santa marta que ejercen sus actividades económicas en el espacio público, esto  con el fin de lograr una caracterización total de población para los posteriores procesos de  carnetización o autorización permanente.`,
    textBanner2:" A continuación selecciona el proceso que deseas realizar",
    consultar:"Consultar",
    registrarse:"Registrarse",
    info:"Más Información",
    card1:"UDEP recuperó 5.863 m2 de espacio público para el disfrute de todos los samarios",
    card2:"Alcaldía Distrital entrega módulos y certificaciones a vendedores y artesanos del Camellón de la Bahía",
    card3:"Avanza recuperación del espacio público en el primer tramo del proyecto de renovación de la carrera Quintan",
    verMas:"Ver más",
    passwordPlaseholder:"Contraseña",
    userPlaseholder:"Nombre de Usuario",
    forgotPassword:"¿Olvidaste tu contraseña?",
    login:"Iniciar Sesión",
    loginFooterInfo1:`*Usted se encuentra en una página de acceso restringido.`,
    loginFooterInfo2:`Únicamente las personas con las credenciales autorizadas podrán hacer uso de este sitio web. El uso no autorizado se encuentra tipificado como delito según el código penal colombiano`,
    next:"Siguiente",
    endRegister:"Finalizar Registro",
    consultaTitulo:"Bienvenido al Sistema de Consulta del Registro Único Vendedores Informales",
    consultaInfo:"Mediante este módulo puedes consultar el estado del registro de vendedores  informales según lo expuesto en el decreto XXXX del XX de XXX de 2023, para que los  vendedores informales que desarrollen actividades económicas en el espacio público puedan acceder a programas de mejoramiento y programas institucionales según sus  condiciones de vulnerabilidad.",
    consultaExitosaTitulo:"Consulta en línea de estado de registro RUVI (Sistema de Registro Único de Vendedores Informales)",
    consultaExitosainfo1:"El número de documento 0.000.000.000 se encuentra REGISTRADO en el sistema de consulta y registro vendedores informales como vendedor informal de ACTIVIDAD ECONÓMICA, el estado actual del registro es PENDIENTE",
    consultaExitosainfo2:" *Fecha de consulta: 00/XXXX/0000 00:00 p.m.",
    consultaExitosainfo3:" 1 Esta consulta es de fines informativos relativos a la identificación, ubicación, actividad económica comercializada y tipo de mobiliario en cumplimiento al decreto XXXX de XXXX de 2022, Artículo XX. Este registro solo se podrá hacer de manera individual, este documento o consulta no constituye reconocimiento alguno de permisos para desarrollar la actividad comercial que ejerce sobre el espacio público, sin embargo, es requisito para postular a dicho permiso.",
    successText:"su registro en el sistema de consulta y registro de vendedores informales se ha realizado de manera EXITOSA",
    tituloFormulario:"Formulario de Registro Único de Vendedores Informales del Distrito de Santa Marta",
    formularioTexto:"En cumplimiento de las disposiciones de la Ley 1581 de 2012 y del Decreto reglamentario 1377 de 2013 que desarrollan el derecho de habeas data, autorizo a LA ALCADÍA DISTRITAL DE SANTA MARTA, en calidad de responsable del tratamiento, para que pueda recopilar, almacenar, archivar, copiar, analizar, usar y consultar los datos que se señalan en el presente formulario de registro. Estos datos serán recolectados por esta para el desarrollo de las actividades del ejercicio de su objeto y sus actividades y no para ningún otro fin."
    
  };
  
  export default text;
  