import * as type from '../types'
const initialState = {
    description: {},
    descriptionText:{},
    loading: false,
    error: null
}

export default function description(state = initialState, action:any) {
    switch (action.type) {
        case type.GET_DESCRIPTION_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case type.GET_DESCRIPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                description: action.description.description.data,
                descriptionText: action.description.descriptionText.data
            }
        case type.GET_DESCRIPTION_FAILED:
            return { 
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state;
            break;
    }
}